import { TopNav } from '@components/layouts/TopNav'
import useEventSubscription from '@hook/useEventSubscription'
import { EVENT_ORG_CHANGED } from '@constants'
import useNavigate from '@hook/useNavigate'
import { useLocation } from 'react-router-dom'
import {
    fetchChecks,
    fetchCollections,
    fetchTracts,
    fetchRunsheets,
    fetchCheckReports,
} from '@actions'
import AppUpdateAvailableAlert from '@components/shared/AppUpdateAvailableAlert'
import AppMaintenanceNotification from '@components/shared/AppMaintenanceNotification'

// MainLayout component that wraps around the application's main content
export const MainLayout = ({ section, children }) => {
    
    const navigate = useNavigate()
    const location = useLocation()
    
    useEventSubscription(EVENT_ORG_CHANGED, () => {
        
        // No need to update anything if we're on the settings screen
        if (location.pathname.includes('/settings'))
            return
        
        const rootRoutes = [
            ['/checks/reports', fetchCheckReports],
            ['/checks', fetchChecks],
            ['/collections', fetchCollections],
            ['/tracts', fetchTracts],
            ['/runsheets', fetchRunsheets],
        ]
        
        for (const [routePath, fetchFn] of rootRoutes) {
            
            if (!location.pathname.includes(routePath))
                continue
            
            // If we're not on the root, redirect there
            if (location.pathname !== routePath)
                navigate(routePath)
            
            // Either way, refresh the data from the
            // current screen & break out of the hook
            return fetchFn()
            
        }
        
        // If none of the above applies, just redirect back to collections
        navigate.toCollections()
        
    }, [])
    
    return (<>
        
        <AppMaintenanceNotification />
        
        <TopNav section={section} />
        
        <div className="mt-2 main-content">
            <div className="container-fluid">{children}</div>
        </div>
        
        <AppUpdateAvailableAlert />
    
    </>)
    
}
