import { Link, Route } from 'react-router-dom'
import { MainLayout } from '@components/layouts/MainLayout'
import Title from '@components/shared/Title'
import AppContent from './AppContent'
import { MdErrorOutline } from 'react-icons/md'
import config from '@/config'

export const showDebugGuides = false

// Should be false in prod
// @todo a nicer 404 page
export const showErrorForRouteFailures = (
    location.hostname === 'localhost' ||
    process.env.NODE_ENV !== 'production'
)

export const debugRouteFailure = (
    <Route path="*" element={
        <div className="">
            <p>ROUTE FAILURE</p>
        </div>
    }/>
)

const ErrorComponent = () => {
    return (
        <div className="flex flex-col items-center justify-center gap-2 my-48 px-14 py-8
            bg-base-100 w-[500px] mx-auto rounded-xl">
            <MdErrorOutline className="text-6xl" />
            <div className="text-4xl mt-4">
                Ooops, that's awkward...
            </div>
            <div className="mb-12 text-xl">Looks like something unexpected happened...</div>
            
            <p>Please refresh the page or click 
                <Link className="link text-primary" to='/collections'>here</Link> to start over</p>
        </div>)
}

export const renderRoute = ({
    path,
    excludeTopNav,
    title,
    section,
    component: Component,
    childRoutes,
    ...rest
}) => (
    
    <Route
        key={path}
        path={path}
        errorElement={<MainLayout section={section}><ErrorComponent /></MainLayout>}
        element={<>
            
            {title && <Title>{title}</Title>}
            
            {excludeTopNav ? (
                <Component {...rest} />
            ) : (
                <MainLayout section={section}>
                    <Component {...rest} />
                </MainLayout>
            )}
            
            {config.enableDataRouter && <AppContent />}
        
        </>}>
        
        {childRoutes?.map(it => renderRoute(it))}
    
    </Route>
    
)
