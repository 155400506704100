import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/halfile')

export const fetchCounties = async () => {
    
    try {
        
        const res = await ParseAPI.get('halfile/counties')
        
        store.counties.setValue(res.data)
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const fetchCountyByKey = async countyKey => {
    
    try {
        
        const res = await ParseAPI.get(`halfile/county/${countyKey}`)
        
        store.currentCounty.setValue(res.data)
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const findDocumentsByFilter = async (countyKey, filter) => {
    
    try {
        
        const res = await ParseAPI.post(`halfile/county/${countyKey}`, { filter })
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const downloadHalfilePdf = async (countyKey, documentId) => {
    try {
        
        return await ParseAPI.get(`/halfile/county/${countyKey}/document/${documentId}`, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
}

