import { publicRoutes, privateRoutes } from '@routes/routesMap'
import { dynamicRouteFor, dynamicPrivateRouteFor, decorateRoutes, populateRoutes } from '@routes/routeUtils'
import DashboardApp from '$/DashboardApp'

const wrapDashboardRoute = async imp => {
    const Component = (await imp).default
    
    return {
        default: () => (
            <DashboardApp>
                <Component />
            </DashboardApp>
        ),
    }
}

const imports = {
    
    // DEBUG
    KitchenSink: import('$routes/KitchenSink'),
    KSHome: import('$routes/KitchenSink/KSHome'),
    KSRenderPdf: import('$routes/KitchenSink/KSRenderPdf'),
    KSLabelStudio: import('$routes/KitchenSink/KSLabelStudio'),
    KSTypography: import('$routes/KitchenSink/KSTypography'),
    
    SignIn: import('@components/authentication/SignIn'),
    SignUp: import('@components/authentication/SignUp'),
    SetPassword: import('@components/authentication/SetPassword'),
    SignupOrgFlow: import('@components/authentication/SignupOrgFlow'),
    Organizations: import('@components/settings/Organizations'),
    Organization: import('@components/settings/Organizations/Organization'),
    Members: import('@components/settings/Organizations/Members'),
    OrgBilling: import('@components/settings/Organizations/Billing'),
    Templates: import('@components/settings/Organizations/Templates'),
    Template: import('@components/settings/Organizations/Template'),
    History: import('@components/settings/History'),
    SetBilling: import('@components/authentication/SetBilling'),
    Purchases: import('@components/settings/Purchases/Purchases'),
    ResendEmail: import('@components/authentication/ResendEmail'),
    ForgotPassword: import('@components/authentication/ForgotPassword'),
    Partners: import('@components/partners'),
    PartnersAnnotate: import('@components/partners/PartnersAnnotate'),
    PartnersDocuments: import('@components/partners/PartnersDocuments'),
    PartnersChecks: import('@components/partners/PartnersChecks'),
    Checks: import('@components/check/Checks'),
    CheckDetails: import('@components/check/CheckDetails'),
    CheckDetailsEdit: import('@components/check/CheckDetailsEdit'),
    CheckPreview: import('@components/check/CheckPreview'),
    Operators: import('@components/check/Operators'),
    OperatorDetail: import('@components/check/Operators/OperatorDetail'),
    Wells: import('@components/check/Wells'),
    WellDetail: import('@components/check/Wells/WellDetail'),
    CheckReports: import('@components/check/CheckReports'),
    Portfolio: import('@components/check/Portfolio'),
    
    // INTERNAL
    Dashboard: wrapDashboardRoute(import('$routes/Home')),
    Profile: wrapDashboardRoute(import('$routes/Profile')),
    Users: wrapDashboardRoute(import('$routes/Users')),
    DashboardOrganizations: wrapDashboardRoute(import('$routes/Organizations')),
    Annotators: wrapDashboardRoute(import('$routes/Annotators')),
    AnnotatorDetail: wrapDashboardRoute(import('$routes/Annotators/AnnotatorDetail')),
    DashboardCollections: wrapDashboardRoute(import('$routes/Collections')),
    CollectionDetail: wrapDashboardRoute(import('$routes/Collections/CollectionDetail')),
    Documents: wrapDashboardRoute(import('$routes/Documents')),
    Queue: wrapDashboardRoute(import('$routes/Queue')),
    AnnotationLabels: wrapDashboardRoute(import('$routes/AnnotationLabels')),
    Firebase: wrapDashboardRoute(import('$routes/Firebase')),
    ElasticSearch: wrapDashboardRoute(import('$routes/ElasticSearch')),
    CostAndRevenue: wrapDashboardRoute(import('$routes/CostAndRevenue')),
    ExampleManager: wrapDashboardRoute(import('$routes/ExampleManager')),
    Presets: wrapDashboardRoute(import('$routes/ExampleManager/Presets')),
    PagerDuty: wrapDashboardRoute(import('$routes/PagerDuty')),
    CheckMappings: wrapDashboardRoute(import('$routes/CheckMappings')),
    CheckImports: wrapDashboardRoute(import('$routes/CheckImports')),
    UserActions: wrapDashboardRoute(import('$routes/UserActions')),
}

const namedImports = {
    Collections: import('@components/collection/Collections'),
    HalfileCounties: import('@components/collection/HalfileCounties'),
    HalfileCountySearch: import('@components/collection/Halfile/HalfileCountySearch'),
    CollectionComponent: import('@components/collection/Collection'),
    DocumentView: import('@components/collection/DocumentView'),
    Tracts: import('@components/tract/Tracts'),
    TractView: import('@components/tract/TractView'),
    TractOwnership: import('@components/tract/TractOwnership'),
    Runsheets: import('@components/tract/Runsheets'),
    Runsheet: import('@components/tract/Runsheet'),
    TitleBreakdown: import('@components/tract/TitleBreakdown'),
    Projects: import('@components/project/Projects'),
    ProjectView: import('@components/project/ProjectView'),
    EmailSent: import('@components/authentication/EmailSent'),
    ResetPassword: import('@components/authentication/ResetPassword'),
    ResetPasswordEmailSent: import('@components/authentication/ResetPasswordEmailSent'),
    Settings: import('@components/settings/Settings'),
    IAM: import('@components/iam/IAM'),
    PersonalInfo: import('@components/settings/PersonalInfo'),
    BillingComponent: import('@components/settings/Billing'),
    StripeElementsWrap: import('@components/shared/StripeElementsWrap'),
}

const importNamed = name => module => ({ default: module[name] })

const routes = Object.keys(imports).reduce((acc, it) => ({
    ...acc,
    // eslint-disable-next-line no-undef
    [it]: React.lazy(() => imports[it]),
}), {})

const namedRoutes = Object.keys(namedImports).reduce((acc, it) => ({
    ...acc,
    // eslint-disable-next-line no-undef
    [it]: React.lazy(() => {
        return namedImports[it].then(importNamed(it))
    }),
}), {})

const map = {
    ...routes,
    ...namedRoutes,
}

const routesPublicDynamic = decorateRoutes(dynamicRouteFor, populateRoutes(publicRoutes, map))
const routesPrivateDynamic = decorateRoutes(dynamicPrivateRouteFor, populateRoutes(privateRoutes, map))

export default [
    ...routesPublicDynamic,
    ...routesPrivateDynamic,
]
