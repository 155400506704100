import Flatpickr from 'react-flatpickr'
import cn from 'classnames'
import dayjs from 'dayjs'

import { relativeTime } from '@/utils/date'
import Accordion from '@/components/shared/Accordion'

import monthSelect from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import UserActionsChart from './UserActionsChart'

const TRACKING_ACTIONS = [
    { key: 'upload_document', label: 'Upload document', billable: false },
    { key: 'add_document_to_runsheet', label: 'Add Document to Runsheet', billable: false },
    { key: 'legal_description_search', label: 'Search by Legal Description', billable: true },
    { key: 'view_index_document', label: 'View Index Document', billable: true },
    { key: 'download_index_document', label: 'Download Index Document', billable: true },
    { key: 'apply_model', label: 'Apply Model', billable: true },
]

const UserActionsDetails = ({ vm }) => {
    
    return (
        <div className="flex flex-col gap-3">
            <div>
                {/*
                @todo
                can switch this active class to use a tab
                https://daisyui.com/components/tab/#boxed
                or radio group
                https://daisyui.com/components/join/#join-radio-inputs-with-btn-style
                */}
                <label>Select a period</label>
                <div className="flex gap-3 justify-start">
                    {vm.periodActions.map(tb => <button
                        key={tb.key}
                        className={cn('btn btn-sm w-24 btn-outline', {
                            'btn-primary': vm.searchParams.get('filterPeriod') === tb.key,
                        })}
                        onClick={() => { vm.setLocked(true); vm.setSearchParams({ filterPeriod: tb.key }) }}>
                        {tb.label}
                    </button>)}
                    {vm.searchParams.get('filterPeriod') === 'custom' && !vm.locked && <div className="flex gap-3">
                        <div className="form-group mb-0 w-36">
                            <Flatpickr
                                className="input input-bordered flatpickr-input"
                                value={vm.startDate}
                                name="startDate"
                                placeholder="From"
                                options={{
                                    maxDate: vm.endDate,
                                    plugins: [new monthSelect()],
                                }}
                                onChange={date => vm.setStartDate(date[0])} />
                        </div>
                        <div className="form-group mb-0 w-36">
                            <Flatpickr
                                className="input input-bordered flatpickr-input"
                                value={vm.endDate}
                                name="endDate"
                                placeholder="To"
                                options={{
                                    minDate: vm.startDate,
                                    plugins: [new monthSelect()],
                                }}
                                onChange={date => vm.setEndDate(dayjs(date[0]).endOf('month').toDate())} />
                        </div>
                    </div>}
                </div>
            </div>
            <div className="grid grid-cols-6 gap-3">
                {TRACKING_ACTIONS.map(a => {
                    const stat = vm.orgStats.find(os => os.action === a.key) || { count: 0, lastAction: null }
                    
                    return (
                        <div
                            key={a.key}
                            className="
                                card flex py-2 px-3 mb-0 flex-col
                                justify-between bg-[var(--parse-background-lighter)]">
                            <div className="pb-4 justify-center flex-col flex items-center text-xs">
                                <span className="font-semibold text-3xl">{stat.count}</span>
                                <div className="text-xs truncate text-gray-300">{a.label}</div>
                            </div>
                            <div className="text-xs leading-5 text-gray-400 text-center">
                                Last action: {relativeTime(stat.lastAction)}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="grid grid-cols-2 gap-3">
                <div className="card flex flex-col">
                    <div className="card-header grow-0">Actions by User</div>
                    <div className="py-2 px-3 h-72 max-h-72 overflow-y-auto flex flex-col gap-2">
                        {vm.userData.map(un =>
                            <Accordion key={un.email} label={un.email}>
                                <ul role="list" className="divide-y divide-gray-600">
                                    {TRACKING_ACTIONS.map(a => {
                                        const stat = un.actions.find(ac => ac.action === a.key) ||
                                            { count: 0, lastAction: null }
                                        
                                        return (
                                            <li
                                                key={`${un.email}_${a.key}`}
                                                className="flex items-center justify-between gap-2 px-4">
                                                <div className="text-xs flex gap-2 items-center">
                                                    <span className="text-2xl">{stat.count}</span>
                                                    <div className="text-gray-300">{a.label}</div>
                                                </div>
                                                <div>
                                                    <span className="text-xs text-gray-400">
                                                        Last action: {relativeTime(stat.lastAction)}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Accordion>,
                        )}
                    </div>
                </div>
                <div className="card flex flex-col">
                    <div className="card-header grow-0">Actions by Month</div>
                    <div className="flex-1 flex items-center justify-center">
                        <UserActionsChart trackingActions={TRACKING_ACTIONS} organization={vm.organization} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserActionsDetails
