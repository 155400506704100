import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/escrows')

export const getUnseenEscrowNotifications = async () => {
    
    return console.log('@todo escrows/unseen')
    
    /* try {
        const res = await ParseAPI.get('escrows/unseen')
        
        store.escrows.setValue(res.data)
    } catch (err) {
        log.e(err)
    } */
    
}

export const dismissAllNotifications = async () => {
    
    try {
        
        await ParseAPI.post('escrows/dismiss')
        
        store.escrows.setValue([])
        
    } catch (err) {
        
        log.e(err)
        
    }
    
}

export const dismissNotification = async id => {
    
    try {
        
        await ParseAPI.put(`escrows/${id}`, {
            userNotified: true,
        })
        
        store.arrayDeleteById('escrows', id)
        
    } catch (err) {
        
        log.e(err)
        
    }
    
}
