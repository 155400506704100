import { useEffect, useState } from 'react'
import * as actions from '$actions'
import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import * as store from '$store'
import { useWireValue } from '@forminator/react-wire'
import ConfirmationModal from '@/components/shared/ConfirmationModal'


const Presets = () => {
    
    const presets = useWireValue(store.presets)
    const [showDocumentsModal, setShowDocumentsModal] = useState(false)
    const [selectedPreset, setSelectedPreset] = useState([])
    const [fetchedPresetDocuments, setFetchedPresetDocuments] = useState([])
    const [markedDocuments, setMarkedDocuments] = useState([])
    const [collections, setCollections] = useState([])
    const [selectedCollection, setSelectedCollection] = useState('')
    const [isInsertingToLiveTable, setIsInsertingToLiveTable] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    
    
    const navigate = useNavigate()
    
    
    const deleteItems = async () => {
        await actions.deletePresets(markedDocuments)
    }
    
    const deployToLiveTable = async () => {
        setIsInsertingToLiveTable(true)
        await actions.deployToLiveTable(selectedPreset)
        setIsInsertingToLiveTable(false)
        setShowDocumentsModal(false)
        
    }
    
    const copyToWorkingTable = async () => {
        setIsInsertingToLiveTable(true)
        await actions.copyToWorkingTable(selectedPreset)
        setIsInsertingToLiveTable(false)
        
    }
    
    useEffect(() => {
        if (selectedPreset.length)
            actions.getAllPresetDocuments(selectedPreset).then(documents => setFetchedPresetDocuments(documents))
    }, [selectedPreset])
    
    useEffect(() => {
        actions.getAllPresets()
        
        actions.getEvalCollections().then(collections => {
            setCollections(collections)
        })
    }, [])
    
    useEffect(() => {
        if (collections.length)
            setSelectedCollection(collections[0].id)
    }, [collections])
    
    return (
        
        <div className="w-full flex flex-col p-4">
            <h2>Presets</h2>
            <div className="py-4 pr-2 text-sm breadcrumbs flex flex-row justify-between">
                <ul>
                    <li><a onClick={() => navigate('/dashboard')}>Dashboard</a></li>
                    <li><a onClick={() => navigate('/dashboard/example-manager')}>Example Manager</a></li>
                    <li>Presets</li>
                </ul>
                
                
                <button
                    className={cn('btn btn-error', {
                        'opacity-20': markedDocuments.length === 0,
                    })}
                    onClick={() => {
                        deleteItems()
                        setMarkedDocuments([])
                    }}
                    disabled={markedDocuments.length === 0}> Delete </button>
            </div>
            
            <table>
                <thead>
                    <tr>
                        <th>
                        
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            System
                        </th>
                        <th>
                            Template
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {presets.map(p => {
                        return (
                            <tr key={p.id}>
                                <td className="w-[10px]">
                                    <input
                                        type="checkbox"
                                        checked={markedDocuments.includes(p.id)}
                                        onChange={event => {
                                            if (event.target.checked)
                                                setMarkedDocuments(prevToDelete =>
                                                    [...prevToDelete, p.id])
                                            else
                                                setMarkedDocuments(prevToDelete => prevToDelete
                                                    .filter(id => id !== p.id))
                                        }} />
                                </td>
                                <td
                                    onClick={() => {
                                        setSelectedPreset(p.id)
                                        setShowDocumentsModal(true)
                                    }}
                                    className="cursor-pointer">
                                    {p.name}
                                </td>
                                <td>
                                    {p.status}
                                </td>
                                <td>
                                    {p.system}
                                </td>
                                <td>
                                    {p.vet?.name}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
            {showDocumentsModal && (
                <CustomModal
                    className='!max-w-[calc(100vw-400px)]'
                    open={showDocumentsModal}
                    modalHandler={() => setShowDocumentsModal(false)}>
                    <div>
                        <ModalHeader>
                            Preset Documents
                        </ModalHeader>
                        
                        <ModalBody>
                            <div className="max-h-[70vh] overflow-auto">
                                <div className='flex pb-4 gap-4'>
                                    <label 
                                        htmlFor="collection"
                                        className='content-center'>
                                        Choose collection to save eval
                                    </label>
                                    <select
                                        required={true}
                                        id='collection'
                                        className="select w-6/12"
                                        value={selectedCollection}
                                        onChange={e => setSelectedCollection(e.target.value)}>
                                        {collections.filter(col => col.isEval).map(it => (
                                            <option key={it.id} value={it.id}>
                                                {it.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <table className="table table-pin-rows">
                                    <thead>
                                        <tr>
                                            <th>Document Name</th>
                                            <th>System</th>
                                            <th>Type</th>
                                            <th>SubType</th>
                                            <th>Description</th>
                                            <th>Relevant Text</th>
                                            <th>Logic</th>
                                            <th>Answer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fetchedPresetDocuments.map(fpd => {
                                            return (
                                                <tr key={fpd.document?.id}>
                                                    <td>{fpd.document?.name}</td>
                                                    <td>{fpd.system}</td>
                                                    <td>{fpd.type}</td>
                                                    <td>{fpd.subType}</td>
                                                    <td>{fpd.description}</td>
                                                    <td>{fpd.relevantText}</td>
                                                    <td>{fpd.logic}</td>
                                                    <td>{fpd.answer}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        
                        </ModalBody>
                        <ModalFooter>
                            <button
                                className='btn btn-secondary'
                                onClick={() => {
                                    setSelectedCollection('')
                                    setShowDocumentsModal(false)
                                }}>
                                Close 
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => actions.createEval(selectedPreset, selectedCollection)}>
                                Evaluate Preset
                            </button>
                            
                            <div className='divider divider-horizontal m-0'></div>
                            
                            <button 
                                className='btn btn-secondary'
                                disabled={isInsertingToLiveTable}
                                onClick={() => {
                                    setShowDocumentsModal(false)
                                    copyToWorkingTable()
                                }}> Copy to WorkingTable </button>
                            <button 
                                className='btn btn-primary'
                                disabled={isInsertingToLiveTable}
                                onClick={() => setShowConfirmationModal(true)}> Deploy to LiveTable </button>
                        </ModalFooter>
                    </div>
                </CustomModal>
                
            )
            }
            
            {showConfirmationModal && (
                <ConfirmationModal
                    showModal={showConfirmationModal}
                    showHideModal={() => setShowConfirmationModal(false)}
                    title='Confirm the deploy'
                    onConfirm={() => deployToLiveTable()}>
                
                </ConfirmationModal>
            )}
        </div >
        
        
    )
}

export default Presets