
// Organization-exclusive statuses
export const ORG_DOCUMENT_STATUS_PROCESSING = 'processing'                 // Document processing
export const ORG_DOCUMENT_STATUS_OCR_APPLIED = 'ocr_applied'               // OCR ran on document
export const ORG_DOCUMENT_STATUS_MODEL_APPLIED = 'model_applied'           // ML model ran on document
export const ORG_DOCUMENT_STATUS_READY_FOR_REVIEW = 'ready_for_review'     // Document may have been reviewed previously
export const ORG_DOCUMENT_STATUS_REVIEW_IN_PROGRESS = 'review_in_progress' // A human is reviewing this document
export const ORG_DOCUMENT_STATUS_REVIEWED = 'reviewed'                     // Annotations reviewed by a human
export const ORG_DOCUMENT_STATUS_FAILED = 'failed'                         // Failed when applying model

export const ORG_DOCUMENT_STATUSES = [
    
    ORG_DOCUMENT_STATUS_PROCESSING,
    ORG_DOCUMENT_STATUS_OCR_APPLIED,
    ORG_DOCUMENT_STATUS_MODEL_APPLIED,
    ORG_DOCUMENT_STATUS_FAILED,
    
    ORG_DOCUMENT_STATUS_READY_FOR_REVIEW,
    ORG_DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    ORG_DOCUMENT_STATUS_REVIEWED,
    
]

export const DOCUMENT_STATUS_UPLOADED = 'uploaded'                 // Document uploaded
export const DOCUMENT_STATUS_SAVED = 'saved'                       // Page images created, ready to process
export const DOCUMENT_STATUS_PROCESSING = 'processing'             // Document processing
export const DOCUMENT_STATUS_OCR_APPLIED = 'ocr_applied'           // OCR ran on document
export const DOCUMENT_STATUS_MODEL_APPLIED = 'model_applied'       // ML model ran on document
export const DOCUMENT_STATUS_READY_FOR_REVIEW = 'ready_for_review' // Document may have been reviewed previously
export const DOCUMENT_STATUS_REVIEW_IN_PROGRESS = 'review_in_progress' // A human is reviewing this document
export const DOCUMENT_STATUS_REVIEWED = 'reviewed'                     // Annotations reviewed by a human

export const DOCUMENT_STATUS_FAILED_PREPROCESS = 'failed_preprocess'   // Document failed when page splitting
export const DOCUMENT_STATUS_FAILED = 'failed' // General failure (may have more specific ones later)

// All document statuses
export const DOCUMENT_STATUSES = [
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
    
    DOCUMENT_STATUS_FAILED_PREPROCESS,
    DOCUMENT_STATUS_FAILED,
]

// Documents that can have their status "reset"
export const DOC_CAN_BE_RESET_STATUSES = [
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
]

// Documents not ready to be processed yet
export const DOC_NOT_READY_STATUSES = [
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
]

// Documents ready to be processed
export const DOC_READY_STATUSES = [
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
]

// Documents that have not been reviewed/annotated yet
export const DOC_NOT_REVIEWED_STATUSES = [
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    
    DOCUMENT_STATUS_FAILED_PREPROCESS,
]

// Documents with in-progress review(s)
export const DOC_REVIEW_IN_PROGRESS_STATUSES = [
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
]

// Documents that have finished reviews/annotations
export const DOC_REVIEWED_STATUSES = [
    DOCUMENT_STATUS_REVIEWED,
]

export const DOC_CAN_PROCESS_STATUSES = [
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_FAILED,
    // DOCUMENT_STATUS_OCR_APPLIED, -- disabled for now because on this step processing button shouldn't appear.
    // ocr_applied and model_applied work together in this
    DOCUMENT_STATUS_UPLOADED,
]

export const ORG_DOCS_CAN_PROCESS_STATUSES = [
    ORG_DOCUMENT_STATUS_FAILED,
]

export const DOC_FAILED_STATUSES = [
    DOCUMENT_STATUS_FAILED,
]

export const DOC_PROCESS_RUNSHEET_STATUSES = [
    ORG_DOCUMENT_STATUS_READY_FOR_REVIEW,
    ORG_DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    ORG_DOCUMENT_STATUS_REVIEWED,
    ORG_DOCUMENT_STATUS_FAILED,
]

export const SEARCH_HEADERS = [
    { id: 'name', label: 'Name', sortable: true },
    { id: 'grantors', label: 'Grantor', sortable: false },
    { id: 'grantees', label: 'Grantee', sortable: false },
    { id: 'bookVolume', label: 'Book Volume', sortable: true },
    { id: 'bookPage', label: 'Book Page', sortable: true },
    { id: 'bookType', label: 'Book Type', sortable: true },
    { id: 'instrumentNumber', label: 'Instr. #', sortable: true },
    { id: 'instrumentType', label: 'Instr. Type', sortable: true },
    { id: 'instrumentDate', label: 'Instr. Date', sortable: true },
    { id: 'fileDate', label: 'File Date', sortable: true },
    { id: 'recordingDate', label: 'Recording Date', sortable: true },
    { id: 'allLegalDescriptions', label: 'Legal Description', sortable: true },
]

export const HIDE_COL_ON_INDEX = ['recordingDate']

export const hasNoFile = document => !document.fileName || document.fileName === 'NAN'

export const DOCUMENT_TYPES = [
    { id: 'affidavit', label: 'Affidavit' },
    { id: 'assignment', label: 'Assignment' },
    { id: 'certificate', label: 'Certificate' },
    { id: 'conveyance', label: 'Conveyance' },
    { id: 'courtCase', label: 'Court Case' },
    { id: 'declaration', label: 'Declaration' },
    { id: 'deed', label: 'Deed' },
    { id: 'easement', label: 'Easement' },
    { id: 'lease', label: 'Lease' },
    { id: 'lien', label: 'Lien' },
    { id: 'miscellaneous', label: 'Miscellaneous' },
    { id: 'mortgage', label: 'Mortgage' },
    { id: 'notice', label: 'Notice' },
    { id: 'order', label: 'Order' },
    { id: 'other', label: 'Other' },
    { id: 'plat', label: 'Plat' },
    { id: 'will', label: 'Will' },
]

export const DOCUMENT_TYPES_MAP = {
    'affidavit': 'Affidavit',
    'assignment': 'Assignment',
    'certificate': 'Certificate',
    'conveyance': 'Conveyance',
    'courtCase': 'Court Case',
    'declaration': 'Declaration',
    'deed': 'Deed',
    'easement': 'Easement',
    'lease': 'Lease',
    'lien': 'Lien',
    'miscellaneous': 'Miscellaneous',
    'mortgage': 'Mortgage',
    'notice': 'Notice',
    'order': 'Order',
    'other': 'Other',
    'plat': 'Plat',
    'will': 'Will',
}
