import { useCallback } from 'react'
import { copyToClipboard } from '@utils'
import { toast } from 'react-toastify'
import cn from 'classnames'

import { FaClipboard } from 'react-icons/fa'

const CopyToClipboardButton = ({
    className,
    iconClassName,
    value,
    onClick,
    onError,
    showToast = true,
    ...props
}) => {
    
    const onCopyToClipboardClick = useCallback(async () => {
        
        try {
            
            await copyToClipboard(value)
            
            if (showToast)
                toast('Copied to clipboard')
            
        } catch (e) {
            
            if (onError && typeof onError === 'function')
                onError(e)
            
        }
        
        if (onClick && typeof onClick === 'function')
            onClick()
        
    }, [value, onClick])
    
    return (
        
        <button
            className={cn(
                'CopyToClipboardButton',
                'opacity-50 hover:opacity-100',
                'transition-opacity ease-in-out duration-200',
                className,
            )}
            onClick={onCopyToClipboardClick}
            {...props}>
            
            <FaClipboard className={iconClassName} />
        
        </button>
        
    )
    
}

export default CopyToClipboardButton
