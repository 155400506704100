import { memo, useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { fetchProfile } from '@actions'
import { useNavigate, useLocation } from 'react-router-dom'
import useInit from '@hook/useInit'
import { useDebug } from '@hook/useDebug'
import useBuildInfo from '@hook/useBuildInfo'
import useTheme from '@hook/useTheme'
import usePendo from '@hook/usePendo'
import useAllExternalScripts from '@hook/useAllExternalScripts'
import { showDebugGuides } from './App.lib'
import { UNAUTHENTICATED_PATHS } from '@constants/routing'
import { useSubscribeIdentifyUser } from '@utils/logrocket'

import CompatibilityErrorsWarning from '@components/shared/CompatibilityErrorsWarning'
import { GlobalPaymentRequiredDialog } from '@components/shared/Dialogs/PaymentRequiredDialog'
import InsufficientBalanceWarningDialog from '@components/shared/Dialogs/InsufficientBalanceWarningDialog'
import { ToastContainer, Slide } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import useAppVersionCheck from '@hook/useAppVersionCheck'

/**
 * Main content of the app, excluding routes.
 * 
 * @see [AppRoutes] for routing
 */
const AppContent = () => {
    
    const navigate = useNavigate()
    const location = useLocation()
    
    const isFetchingUser = useWireValue(store.isFetchingUser)
    const user = useWireValue(store.user)
    const compatErrors = useWireValue(store.compatErrors)
    const ignoreCompatErrors = useWireValue(store.ignoreCompatErrors)
    
    useInit()
    useSubscribeIdentifyUser()
    
    useBuildInfo()
    useTheme()
    usePendo()
    useAllExternalScripts()
    useDebug()
    useAppVersionCheck()
    
    useEffect(() => { fetchProfile() }, [])
    
    useEffect(() => {
        
        const hasStage = user?.stage === 'filled'
        const needsOrg = user?.id && !user?.organizations?.length
        
        // Need the path check here, or it infinite loops the redirect
        if (hasStage && needsOrg && !UNAUTHENTICATED_PATHS.includes(location.pathname))
            // Timeout b/c React Router doesn't like navigating while rendering
            setTimeout(() => navigate('/setorganizations'), 1000)
        
    }, [user, location.pathname])
    
    if (isFetchingUser || !user?.organizations?.length) return null
    
    return (
        
        <>
            
            <GlobalPaymentRequiredDialog />
            <InsufficientBalanceWarningDialog />
            
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                transition={Slide}
                theme="light"/>
            
            <Tooltip variant="info" className="max-w-[300px]" id="default-tip" />
            
            {compatErrors.length > 0 && !ignoreCompatErrors && (
                <CompatibilityErrorsWarning errors={compatErrors} />
            )}
            
            {showDebugGuides && (
                <div id="debug-global-guides" style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    width: '100%',
                    height: '100vh',
                    overflow: 'hidden',
                }}>
                    <div className="absolute top-0 w-px h-screen border !border-red-500 DEBUG-PAGE-LINE-V left-1/2" />
                    <div className="w-screen h-px border !border-red-500 DEBUG-PAGE-LINE-H absolute-centered" />
                </div>
            )}
        
        </>
        
    )
    
}

export default memo(AppContent)
