import CustomModal from '../shared/CustomModal/CustomModal'
import ModalBody from '../shared/CustomModal/ModalBody'
import ModalFooter from '../shared/CustomModal/ModalFooter'
import ModalHeader from '../shared/CustomModal/ModalHeader'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '@actions'
import { SYSTEM_TYPES } from '@/constants/exampleManager'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import cn from 'classnames'
import ScrollableDocumentViewer from '../collection/DocumentViewerV3/ScrollableDocumentViewer'
import { buildRecordUrl } from '@/utils'

const ExampleManagerModal = (
    {
        show,
        handleClose,
        example,
        document,
    },
) => {
    
    const subTabs = useWireValue(store.subTabs)
    const [exampleData, setExampleData] = useState(example || 
        { documentId: document.id, system: SYSTEM_TYPES[0].key })
    const [invalidValue, setInvalidValue] = useState(false)
    
    const pdfUrl = useMemo(() => buildRecordUrl(document, true, document?.collection?.isParse), [document])
    
    const updateByKey = (key, value) => {
        const copy = { ...exampleData }
        
        copy[key] = value
        
        setExampleData(copy)
        
    }
    
    const upsertTheExample = async () => {
        
        if (exampleData.system === 'value_extractor' && !exampleData.valueExtractorTemplateId) {
            setInvalidValue(true)
        } else {
            setInvalidValue(false)
            
            if (example) {
                
                await actions.editDocumentAsExample(exampleData.id, exampleData)
                handleClose(false)                        
                
            } else {
                
                await actions.saveDocumentAsExample(exampleData)
                handleClose(false)                        
                
            }
        }
    }
    
    useEffect(() => {
        actions.getSubTabs()
    },[])
    
    return (
        <CustomModal
            open={show}
            modalHandler={handleClose}
            className='max-w-[min(1300px,80vw)]'>
            <ModalHeader>
                {example ? 'Edit' : 'Create'} Example
            </ModalHeader>
            <ModalBody>
                <div className="flex gap-4">
                    <div className="flex-1">
                        <ScrollableDocumentViewer url={pdfUrl} />
                    </div>
                    <form className="flex-1 flex flex-col gap-4">
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col'>
                                <label htmlFor="system">
                                    System
                                </label>
                                <select
                                    required={true}
                                    id='system'
                                    className="select"
                                    value={exampleData.system}
                                    onChange={e => updateByKey('system', e.target.value)}>
                                    {SYSTEM_TYPES.map(it => (
                                        <option key={it.key} value={it.key}>
                                            {it.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            
                            {exampleData.system === 'value_extractor' && (
                                <div className='flex flex-col'>
                                    <label htmlFor='subsystem'>
                                        Template
                                    </label>
                                    <div className='flex flex-col gap-0'>
                                        <select
                                            required={true}
                                            id='subsystem'
                                            className={cn('select', {
                                                'border-red-500 peer': invalidValue,
                                            })}
                                            defaultValue={''}
                                            value={exampleData.valueExtractorTemplateId}
                                            onChange={e => {
                                                updateByKey('valueExtractorTemplateId', e.target.value)
                                            }}>
                                            <option disabled defaultValue={''}></option>
                                            {subTabs.map(it => (
                                                <option key={it.id} value={it.id}>
                                                    {it.name}
                                                </option>
                                            ))}
                                        </select>
                                        <p className="invisible peer-invalid:visible text-red-500 text-sm">
                                            Please provide a valid template.
                                        </p>
                                    </div>
                                
                                </div>
                            )}
                        </div>
                        
                        <div className="flex gap-4">
                            <div className='flex flex-col w-6/12'>
                                <label htmlFor='type'>
                                    Type
                                </label>
                                <input
                                    type="text"
                                    id='type'
                                    className=" input input-bordered"
                                    placeholder="Type"
                                    value={exampleData.type}
                                    onChange={e => updateByKey('type', e.target.value)} />
                            </div>
                            <div className='flex flex-col w-6/12'>
                                <label htmlFor='subtype'>
                                    Subtype
                                </label>
                                <input
                                    type="text"
                                    id='subtype'
                                    className=" input input-bordered"
                                    placeholder="SubType"
                                    value={exampleData.subType}
                                    onChange={e => updateByKey('subType', e.target.value)} />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <label htmlFor='description'>
                                Description
                            </label>
                            <input
                                type="text"
                                id='description'
                                className="input input-bordered"
                                placeholder="One sentence description/summary"
                                value={exampleData.description}
                                onChange={e => updateByKey('description', e.target.value)} />
                        </div>
                        
                        <div className='flex flex-col'>
                            <label htmlFor='relevantText'>
                                Relevant Text
                            </label>
                            <textarea
                                className="input input-bordered h-36"
                                id='relevantText'
                                placeholder="Relevant text"
                                value={exampleData.relevantText}
                                onChange={e => updateByKey('relevantText', e.target.value)} />
                        </div>
                        
                        <div className='flex flex-col'>
                            <label htmlFor='logic'>
                                Logic
                            </label>
                            <input
                                type="text"
                                id='logic'
                                className="input input-bordered"
                                placeholder="Logic"
                                value={exampleData.logic}
                                onChange={e => updateByKey('logic', e.target.value)} />
                        </div>
                        
                        <div className='flex flex-col'>
                            <label htmlFor='answer'>
                                Answer
                            </label>
                            <input
                                type="text"
                                id='answer'
                                className="input input-bordered"
                                placeholder="Answer: None"
                                value={exampleData.answer}
                                onChange={e => updateByKey('answer', e.target.value)} />
                        
                        </div>
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    className='btn btn-primary btn-outline'
                    onClick={() => handleClose(false)}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        upsertTheExample()
                    }}>
                    {example ? 'Update' : 'Create'}
                </button>
            </ModalFooter>
        </CustomModal>
        
    )
}

export default ExampleManagerModal