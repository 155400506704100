import { useEffect, useMemo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import useUserHasRoles from '@hook/useUserHasRoles'
import { useAccessUser } from '@hook/useAccessUser'
import { ROLE_PARSE_ADMIN, ROLE_SUBSCRIPTION_ADMIN } from '@constants/roles'
import cn from 'classnames'

import AccountMenu from '@components/shared/AccountMenu'
import CurrentOrgProjectSelectorNavItem from '@components/shared/CurrentOrgProjectSelectorNavItem'
import CurrentOrgProjectSelectorDialog from '@components/shared/Dialogs/CurrentOrgProjectSelectorDialog'

import './TopNav.css'
import NotificationCenter from '../shared/AccountMenu/NotificationCenter'

// All nav items, including "admin only" ones
const allNavItems = [
    { name: 'Collections', path: 'collections' },
    
    // 2024-02-21 disabled while deploying global org selector; will enable again soon
    // { name: 'Projects', path: 'projects' },
    
    // December 30th, 2022
    // Projects & tracts hidden for now, until they are fully functional
    // https://app.clickup.com/t/863frvtz4
    { name: 'Tracts', path: 'tracts' },
    { name: 'Runsheets', path: 'runsheets' },
    
    /* { name: 'Contracts', path: 'contracts', onlyAdmin: true, disabled: true },
    { name: 'Reports', path: 'reports', onlyAdmin: true, disabled: true }, */
    
    // April 27th, 2023
    // Hiding this for now, since Organizations will likely deprecate it
    /* { name: 'IAM', path: 'iam', onlyAdmin: true }, */
]

// Nav items for regular users (non-admin)
const userNavItems = allNavItems.filter(it => !it.onlyAdmin)

const checksNavItems = [
    { name: 'Portfolio', path: 'checks/portfolio' },
    { name: 'Operators', path: 'checks/operators', end: false },
    { name: 'Wells', path: 'checks/wells' },
    { name: 'Reports', path: 'checks/reports' },
]

export const TopNav = ({ section }) => {
    
    const location = useLocation()
    const navigate = useNavigate()
    
    const user = useWireValue(store.user)
    const apiConfig = useWireValue(store.apiConfig)
    
    const isAdmin = useUserHasRoles([ROLE_PARSE_ADMIN, ROLE_SUBSCRIPTION_ADMIN])
    const isCheckManager = useUserHasRoles('Check Manager')
    
    const userAllowedChecks = useAccessUser({
        accessType: 'checks',
        accessName: 'view_checks',
    })
    
    const shouldShowChecks = useMemo(() => (
        isCheckManager && userAllowedChecks
    ), [isCheckManager, userAllowedChecks, location])
    
    // Nav items to show, based on the user's role(s)
    const navItems = useMemo(() => {
        
        // Intentionally shallow cloning so we don't mutate the original lists
        const items = [...(isAdmin ? allNavItems : userNavItems)]
        
        if (shouldShowChecks) {
            
            if (!items.some(it => it.name === 'Checks'))
                items.push({ name: 'Checks', path: 'checks' })
            
            // Prevent duplicates & add check routes,
            // but only if we're in the top-level Checks parent category
            // if (location.pathname === '/checks')
            checksNavItems.map(item => {
                if (!items.some(it => it.name === item.name))
                    items.push(item)
            })
            
        }
        
        return items
        
    }, [isAdmin, shouldShowChecks, location])
    
    const environmentBanner = useMemo(() => (
        apiConfig.mode?.startsWith('prod') ? null
            : apiConfig.isLocal ? 'localhost' : apiConfig.mode
    ), [apiConfig])
    
    useEffect(() => {
        
        if (location.pathname === '/iam' && !isAdmin)
            navigate('/collections')
        
    }, [user, isAdmin, location.pathname])
    
    return (
        
        <nav id="TopNav">
            
            {/* <!-- Brand --> */}
            <div className={cn('relative logo-wrap grow-0 shrink-0', {
                withEnvironmentBanner: environmentBanner,
            })}>
                <img
                    className="logo"
                    src="/img/logo-tiny.png"
                    alt=""
                    title={apiConfig.dataEnv === 'prod' ? null : apiConfig.dataEnv}/>
                {environmentBanner && (
                    <div id="environmentBanner">
                        {environmentBanner}
                    </div>
                )}
            </div>
            
            {/* <!-- Toggler --> */}
            <button
                className="mr-auto navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>
            
            <div className="navbar-links grow">
                <ul className="menu menu-horizontal px-1 gap-1">
                    
                    <CurrentOrgProjectSelectorNavItem />
                    
                    {navItems.map((navItem, index) => {
                        
                        const { name, path, disabled, end } = navItem
                        
                        return (
                            <li key={index}>
                                <NavLink
                                    className={cn('nav-link', {
                                        active: path === section,
                                    })}
                                    to={`/${path}`}
                                    onClick={e => {
                                        if (disabled) {
                                            e.preventDefault()
                                        }
                                    }}
                                    aria-disabled={true}
                                    end={end !== false}
                                    data-test-id={`TopNav-${name.replace(/\s/g, '')}`}>
                                    {name}
                                </NavLink>
                            </li>
                        )
                        
                    })}
                
                </ul>
            </div>
            
            {apiConfig?.remoteConfig?.notificationCenterEnabled && (
                <NotificationCenter />
            )}
            
            <AccountMenu/>
            
            <CurrentOrgProjectSelectorDialog />
        
        </nav>
        
    )
    
}
