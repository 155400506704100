import { useEffect } from 'react'
import LogRocket from 'logrocket'
import config from '@/config'
import buildInfo from '@/build.json'
import { user, hasIdentifiedWithLogRocket } from '@store'
import { getUserFullName } from '@utils'

const useLogRocket = (
    config.logRocket.enabled &&
    config.logRocket.id?.length > 0
)

if (useLogRocket) {
    console.info('Initializing LogRocket')
    LogRocket.init(config.logRocket.id, {
        release: buildInfo.hash,
    })
}

/**
 * Subscribes to the user store and logs the user in to LogRocket
 * 
 * @returns {function} Unsubscribe function
 */
export const subscribeIdentifyUser = () => {
    
    if (!useLogRocket)
        return console.log('LogRocket not enabled')
    
    return user.subscribe(value => {
        
        // Don't identify if we've already identified
        if (hasIdentifiedWithLogRocket.getValue())
            return
        
        LogRocket.identify(value.id, {
            name: getUserFullName(value, false) ?? 'Unknown User',
            email: value.email,
            
            // Add your own custom user variables here, ie:
            // subscriptionType: 'pro'
        })
        
        console.log('LogRocket identified user', value.email)
        hasIdentifiedWithLogRocket.setValue(true)
        
    })
    
}

/**
 * Subscribes to the user store and logs the user in to LogRocket
 * Automatically unsubscribes when the component unmounts
 */
export const useSubscribeIdentifyUser = () => {
    
    useEffect(() => {
        
        if (!config.logRocket.enabled)
            return
        
        const unsubscribe = subscribeIdentifyUser()
        
        return () => unsubscribe?.()
        
    }, [])
    
}
