/**
 * Title cases a string
 *
 * @example titleCase('hello world') -> "Hello world"
 *
 * @param text String input
 * @returns Title-cased {text}
 */
const _titleCase = text => {
    try {
        const first = text?.substring(0, 1)?.toUpperCase() || ''
        const rest = text?.substring(1) || ''
        
        return first + rest
    } catch (e) {
        return text
    }
}

/**
 * Title cases a string
 *
 * @example titleCase('hello world') -> 'Hello world'
 * @example titleCase('hello world', true) -> 'Hello World'
 *
 * @param text String input
 * @param global Apply title casing to every word in {text}
 * @returns Title-cased {text}
 */
export const titleCase = (text, global = false) => {
    try {
        if (!global) return _titleCase(text)
        
        return text?.split(' ')?.map(_titleCase)?.join(' ')
    } catch (e) {
        return text
    }
}

/**
 * Wrapper to simplify the update of search params without messing with the existing params
 * 
 * @example setSearchParams(updateSearchParam('query', 'abc'))
 * 
 * @param key Search param key to be updated
 * @param value Search param value to be updated
 * @returns function to be used in a useSearchParams setter
 */
export const updateSearchParam = (key, value) => prev => {
    const nParams = new URLSearchParams(prev)
    
    nParams.set(key, value)
    return nParams
}

/**
 * Switches the party name from A, B to B A
 * 
 * @example switchPartyNames('Bliss, Wes') returns 'Wes Bliss' :P
 * 
 * @param {String} name 
 * @returns Switched Name
 */
export const switchPartyNames = name => {
    const companyList = ['LLC', 'INC.', 'INC,']
    const ends = ['FLCA', 'INC', ' NA', 'N.A.', ',NA', 'FA', 'SSB', 'LTD', ' LP', ',LP', 'PCA', 'L.P.']
    
    const splitName = name.split(',')
    
    if (splitName.length > 1
        && !companyList.some(word => name.includes(word))
        && !ends.some(word => name.endsWith(word)))
        return `${splitName[1].trim()} ${splitName[0].trim()} ${splitName.slice(2).join('')}`
            .replace(/ {2}/g, ', ').trim()
    return name
}