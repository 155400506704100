import { useEffect } from 'react'
import { useWireState } from '@forminator/react-wire'
import { theme as storeTheme } from '@store'
import { themes } from '@/constants'

const useTheme = () => {
    
    /** @type WireState<string> */
    const [theme, setTheme] = useWireState(storeTheme)
    
    useEffect(() => {
        if (theme === null) {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                setTheme(themes.find(it => it.key === 'deep'))
            } else {
                setTheme(themes.find(it => it.key === 'light'))
            } 
        } else {
            
            let name = `parse-${theme.key}`
            
            // eslint-disable-next-line no-restricted-globals
            const el = document.querySelector('html')
            
            el.setAttribute('data-theme', name)
            
            el.setAttribute('data-mode', theme.isDark ? 'dark' : 'light')
            
        }
    }, [theme])
    
}

export default useTheme
