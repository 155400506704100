import { useWireValue } from '@forminator/react-wire'
import { theme as storeTheme, remoteConfig as storeRemoteConfig } from '@store'
import { switchThemeNext } from '@actions'
import { noop } from '@utils'

import ThemeIcon from '../../../src/components/shared/ThemeSwitcher/ThemeIcon'
import { Link } from 'react-router-dom'
import { MdClose, MdInfoOutline } from 'react-icons/md'

const SidebarQuickSettings = ({
    toggle,
}) => {
    
    const theme = useWireValue(storeTheme)
    const remoteConfig = useWireValue(storeRemoteConfig)
    
    const switchTheme = e => {
        
        e.preventDefault()
        switchThemeNext()
        
    }
    
    return (
        
        <nav className="SidebarQuickSettings">
            
            <header>
                <span className="opacity-60">
                    Quick Settings
                </span>
                <button
                    className="btn btn-circle w-10 h-10 !p-0 text-primary-content"
                    onClick={toggle}>
                    <MdClose className="text-lg" />
                </button>
            </header>
            
            <ul className="menu mt-6 bg-base-300/30 rounded-md">
                {/* @todo remove this check once themes are live */
                    remoteConfig?.themes?.enabled && (
                        <li className="list-group-item">
                            <Link
                                className="flex items-center justify-end"
                                to="/"
                                onClick={switchTheme}>
                                <span>Theme: {theme.name}</span>
                                <ThemeIcon theme={theme}/>
                            </Link>
                        </li>
                    )}
                <li className="list-group-item">
                    <Link
                        className="flex items-center justify-end"
                        to="/"
                        onClick={noop}>
                        <span>@todo what else?</span>
                        <MdInfoOutline className="text-lg" />
                    </Link>
                </li>
            </ul>
        
        </nav>
        
    )
    
}

export default SidebarQuickSettings
