import * as store from '.'
import { dedupe } from '@utils'
import { deepmergeWithArrays } from '@utils'
import equal from 'fast-deep-equal'

export const dump = () => {
    
    const val = Object.keys(store).reduce((acc, it) => {
        
        if (!store[it].getValue) return acc
        
        return {
            ...acc,
            [it]: store[it].getValue(),
        }
        
    }, {})
    
    console.log(val)
    
}

export const getCounts = () => {
    
    return Object.keys(store).reduce((acc, it) => {
        
        if (!store[it].getValue) return acc
        
        const val = store[it].getValue()
        
        let counts = 0
        
        if (val) {
            
            if (Array.isArray(val))
                counts = val.length
            
            if (val.rows && Array.isArray(val.rows))
                counts = val.rows.length
            
            counts = Object.keys(val).length
            
        }
        
        return {
            ...acc,
            [it]: counts,
        }
        
    }, {})
    
}

export const dumpCounts = () => {
    
    const val = getCounts()
    
    console.log(val)
    
}

export const trackCountsFn = () => {
    
    if (!window.debug)
        window.debug = {}
    
    const val = getCounts()
    
    if (!window.debug.storeCounts) {
        window.debug.storeCounts = getCounts()
        return
    }
    
    const isNada = x => x === undefined || x === null
    
    // Compare prev to current
    
    const diff = {}
    
    for (const k of Object.keys(val)) {
        
        const prev = window.debug.storeCounts[k]
        
        // No change
        if (isNada(prev[k]) || isNada(val[k]) || prev[k] === val[k])
            continue
        
        if (prev[k] > val[k])
            diff[k] = `${prev[k]} -> ${val[k]}, -${prev[k] - val[k]}`
        else
            diff[k] = `${prev[k]} -> ${val[k]}, +${val[k] - prev[k]}`
        
    }
    
    if (Object.keys(diff).length > 0)
        console.log('trackCounts', diff)
    
}

export const trackCounts = () => {
    
    if (!window.debug)
        window.debug = {}
    
    if (window.debug.trackCountsRef) {
        clearInterval(window.debug.trackCountsRef)
        window.debug.trackCountsRef = null
        console.log('trackCounts: off')
    } else {
        window.debug.trackCountsRef = setInterval(trackCountsFn, 1000)
        console.log('trackCounts: on')
    }
    
}

export const arrayAdd = (key, value, atBeginning = false) => {
    
    let storeValue = store[key].getValue() || []
    
    if (Array.isArray(storeValue))
        store[key].setValue(atBeginning ? [ value, ...storeValue ] : [ ...storeValue, value ])
    else if (Object.hasOwn(storeValue, 'rows'))
        store[key].setValue({
            count: (storeValue.count + 1) || 0,
            rows: atBeginning ? [ value, ...storeValue.rows ] : [ ...storeValue.rows, value ],
        })
    
}

export const arrayAddMany = (key, value) => {
    
    const values = dedupe([
        ...(store[key].getValue() || []),
        ...value,
    ])
    
    store[key].setValue(values)
    
}

export const _arrayUpdateById = (prev, next, merge = null) => {
    
    if (!next.id)
        throw new Error('store/helpers/arrayUpdateById value has invalid ID ' +
            JSON.stringify({ prev, next, merge }, null, 4))
    
    const data = prev || []
    
    return data.map(it => {
        
        if (it.id === next.id)
            return merge ? merge(it, next) : next
        
        return it
        
    })
    
}

export const arrayUpdateById = (key, value, merge = null) => {
    
    if (!value.id)
        throw new Error('store/helpers/arrayUpdateById value has invalid ID ' +
            JSON.stringify({ key, value, merge }, null, 4))
    
    const data = store[key].getValue() || []
    
    store[key].setValue(_arrayUpdateById(data, value, merge))
    
}

export const arrayUpsertById = (key, value, merge = null) => {
    
    const exists = store[key].getValue()?.some(it => it.id === value.id)
    
    if (exists)
        return arrayUpdateById(key, value, merge)
    
    return arrayAdd(key, value)
    
}

/**
 * Upserts an item into an array stored in a key-value store by its ID,
 * optionally merging the new value with an existing one.
 *
 * @param {string} key - The key under which the array is stored in the store.
 * @param {*} value - The value to be inserted or merged into the array. Must have an `id` property.
 * @param {boolean|Function} [merge=false] - True to use `deepmergeWithArrays` or a custom function
 * used to merge the new value with an existing one in the array.
 * If provided, it should take two arguments: the existing item and the new value.
 * If not provided and the second parameter is truthy, {@link deepmergeWithArrays} is used instead.
 * @returns {void}
 */
export const arrayUpsertByIdWithCount = (key, value, merge = false) => {
    
    const prev = store[key].getValue() ?? { count: 0, rows: [] }
    
    prev.rows = prev.rows ?? []
    
    const exists = prev.rows.some(it => it.id === value.id)
    
    const next = exists
        ? prev.rows.map(it => {
            
            if (it.id !== value.id) return it
            
            if (typeof merge === 'function')
                return merge(it, value)
            
            if (merge)
                return deepmergeWithArrays(it, value)
            
            return value
            
        })
        : [...prev.rows, value]
    
    store[key].setValue({
        ...prev,
        count: exists ? (prev.count || 0) : ((prev.count || 0) + 1),
        rows: next,
    })
    
}

export const arrayDeleteById = (key, id) => {
    
    if (!id)
        throw new Error('store/helpers/arrayDeleteById value has invalid ID')
    
    const data = store[key].getValue() || []
    
    store[key].setValue(data.filter(it => it.id !== id))
    
}

export const objectRowsDeleteById = (key, id) => {
    
    if (!id)
        throw new Error('store/helpers/objectRowsDeleteById value has invalid ID')
    
    const data = store[key].getValue() || { count: 0, rows: [] }
    
    store[key].setValue({ count: data.count, rows: data.rows.filter(it => it.id !== id) })
    
}

/* export const objectAdd = (key, value) => {
    
    store[key].setValue
    
} */

export const objectUpdateById = (key, value) => {
    
    const data = store[key].getValue() ?? {}
    
    store[key].setValue({
        ...data,
        [value.id]: value,
    })
    
}

export const saveMergedUserLocal = updatedUser => {
    
    const prev = store.user.getValue() || {}
    
    if (equal(prev, updatedUser)) return
    if (equal(prev, deepmergeWithArrays(prev, updatedUser))) return
    
    store.user.setValue(deepmergeWithArrays(prev, updatedUser))
    
}
