import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import ExampleManagerModal from '../../../src/components/exampleManager/ExampleManagerModal'
import * as store from '$store'
import { MdOutlineAddBox } from 'react-icons/md'
import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { SYSTEM_TYPES, SYSTEM_TYPE_DEED_VALUES } from '@/constants/exampleManager'
import { useNavigate } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import ConfirmationModal from '@/components/shared/ConfirmationModal'

const DashboardExampleManager = () => {
    
    const subTabs = useWireValue(store.subTabs)
    const examples = useWireValue(store.examples)
    const [activeTab, setActiveTab] = useState('comments')
    const [activeSubTab, setActiveSubTab] = useState(subTabs[0]?.id)
    const [markedDocuments, setMarkedDocuments] = useState([])
    const [showCreateExample, setShowCreateExample] = useState(false)
    const [example, setExample] = useState(null)
    const [includeSubTabModal, setIncludeSubTabModal] = useState(false)
    const [newSubTabName, setNewSubTabName] = useState('')
    const [showCreatePreset, setShowCreatePreset] = useState(false)
    const [presetName, setPresetName] = useState('')
    const [isInsertingToLiveTable, setIsInsertingToLiveTable] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [createdPresetId, setCreatedPresetId] = useState('')
    
    const navigate = useNavigate()
    
    const deleteItems = async () => {
        await actions.deleteExamples(markedDocuments)
    }
    
    const createNewSubTab = async () => {
        
        await actions.addNewSubTab(newSubTabName)
        
        setIncludeSubTabModal(false)
        setNewSubTabName('')
        
    }
    
    const deployToLiveTable = async () => {
        setIsInsertingToLiveTable(true)
        await actions.deployToLiveTable(createdPresetId)
        setIsInsertingToLiveTable(false)
        
    }
    
    const loadFromLiveTable = async () => {
        setIsInsertingToLiveTable(true)
        await actions.loadFromLiveTable(activeTab, activeSubTab)
        setIsInsertingToLiveTable(false)
        
    }
    
    const arrayToRender = useMemo(() => {
        
        const data = []
        
        for (let i = 0; i < examples.length; i++) {
            const e = examples[i]
            
            if (activeTab === e.system) {
                if (e.system === SYSTEM_TYPE_DEED_VALUES.key) {
                    if (activeSubTab === e.valueExtractorTemplateId) {
                        data.push(e)
                    }
                } else {
                    data.push(e)
                }
            }
        }
        
        return data
        
    }, [examples, activeTab, activeSubTab])
    
    
    useEffect(() => {
        
        if (activeTab !== SYSTEM_TYPE_DEED_VALUES.key)
            setActiveSubTab('')
        else
            setActiveSubTab(subTabs[0]?.id)
        
    }, [activeTab])
    
    useEffect(() => {
        
        actions.getExamples()
        
        actions.getSubTabs()
        
    }, [])
    
    return (
        
        <div className="p-4 w-full">
            <header>
                <h2>Example Manager</h2>
            </header>
            <div>
                <div className="flex flex-col">
                    <div role="tablist" className="tabs tabs-boxed mt-4 w-6/12 shadow-md">
                        {SYSTEM_TYPES.map(tab =>
                            <a
                                key={tab.key}
                                role="tab"
                                className={cn('tab', { 'tab-active': activeTab === tab.key })}
                                onClick={() => {
                                    setActiveTab(tab.key)
                                    setActiveSubTab('')
                                    
                                }}>
                                {tab.value}
                            </a>,
                        )}
                    </div>
                    {activeTab === SYSTEM_TYPE_DEED_VALUES.key && (
                        <div className="flex flex-row gap-2 mt-4">
                            <div role="tablist"
                                className="tabs tabs-boxed shadow-md gap-1">
                                <a className="tab cursor-pointer flex items-center
                                        hover:bg-base-100 px-2 tooltip tooltip-right"
                                data-tip="Add a new Template"
                                onClick={() => setIncludeSubTabModal(true)}>
                                    <MdOutlineAddBox className="text-xl" />
                                </a>
                                {subTabs.map(subTab =>
                                    <a
                                        key={subTab.id}
                                        role="tab"
                                        className={cn('tab', { 'tab-active': activeSubTab === subTab.id })}
                                        onClick={() => setActiveSubTab(subTab.id)}>
                                        {subTab.name}
                                    </a>,
                                )}
                            </div>
                        </div>
                    )}
                </div>
                
                <div className="flex flex-col">
                    <div className="flex gap-4 self-end mt-4">
                        <button
                            className="btn btn-primary"
                            onClick={() => setShowCreatePreset(true)}> Create Preset </button>
                        <button
                            className="btn btn-secondary"
                            onClick={() => navigate('./presets')}> View Presets </button>
                        <div className="divider divider-horizontal m-0"></div>
                        <button 
                            className="btn btn-primary"
                            disabled={isInsertingToLiveTable}
                            onClick={() => loadFromLiveTable()}> Load from LiveTable </button>
                        <button
                            className={cn('btn btn-error', {
                                'opacity-20': markedDocuments.length === 0,
                            })}
                            onClick={() => {
                                deleteItems()
                                setMarkedDocuments([])
                            }}
                            disabled={markedDocuments.length === 0}> Delete </button>
                    </div>
                    <table className="mt-4 w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Document Name</th>
                                <th>Type</th>
                                <th>Subtype</th>
                                <th>One Sentence Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayToRender.map(example => {
                                return (
                                    <tr key={example.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={markedDocuments.includes(example.id)}
                                                onChange={event => {
                                                    if (event.target.checked)
                                                        setMarkedDocuments(prevToDelete =>
                                                            [...prevToDelete, example.id])
                                                    else
                                                        setMarkedDocuments(prevToDelete => prevToDelete
                                                            .filter(id => id !== example.id))
                                                }} />
                                        </td>
                                        <td>
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setShowCreateExample(true)
                                                    setExample(example)
                                                }}>
                                                {example.document?.name}
                                            </div>
                                        </td>
                                        <td>{example.type}</td>
                                        <td>{example.subType}</td>
                                        <td>
                                            <div className="truncate">
                                                {example.description}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                
                </div>
            </div>
            
            {showConfirmationModal && (
                <ConfirmationModal
                    showModal={showConfirmationModal}
                    showHideModal={() => setShowConfirmationModal(false)}
                    title='Confirm the deploy'
                    onConfirm={() => deployToLiveTable()}>
                
                </ConfirmationModal>
            )}
            
            {showCreatePreset && (
                <CustomModal
                    open={showCreatePreset}
                    modalHandler={() => setShowCreatePreset(false)}>
                    <ModalHeader>
                        Create preset to evaluation
                    </ModalHeader>
                    
                    <ModalBody>
                        <div className="flex flex-col p-4 gap-4">
                            
                            <div className="flex justify-between">
                                <label htmlFor='name'>
                                    Preset Name
                                </label>
                                <input
                                    type='text'
                                    id='name'
                                    className="input input-bordered w-10/12"
                                    value={presetName}
                                    onChange={e => setPresetName(e.target.value)}>
                                </input>
                            </div>
                        
                        
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button 
                            className="btn btn-secondary"
                            onClick={() => {
                                setPresetName('')
                                setShowCreatePreset(false) }}>
                            Cancel
                        </button>
                        <button 
                            className="btn btn-primary"
                            onClick={async ()=> {
                                
                                const payload = {}
                                
                                if (activeSubTab) {
                                    payload.presetName = presetName
                                    payload.system = activeTab
                                    payload.valueExtractorTemplateId = activeSubTab
                                } else {
                                    payload.presetName = presetName
                                    payload.system = activeTab
                                }
                                
                                const res = await actions.createPreset(payload)
                                
                                setCreatedPresetId(res.id)
                                setPresetName('')
                                setShowCreatePreset(false)
                            }}>
                            Create
                        </button>
                    </ModalFooter>
                </CustomModal>
            )}
            
            {includeSubTabModal && (
                <CustomModal
                    open={includeSubTabModal}
                    modalHandler={() => setIncludeSubTabModal(!includeSubTabModal)}>
                    
                    <ModalHeader>
                        New Value Extractor Template
                    </ModalHeader>
                    
                    <ModalBody className="flex gap-4">
                        <input
                            type='text'
                            className="w-10/12 input input-bordered"
                            placeholder='Template Name'
                            value={newSubTabName}
                            onChange={e => setNewSubTabName(e.target.value)} />
                        <button
                            onClick={() => createNewSubTab()}
                            className="btn btn-primary">
                            Create
                        </button>
                    </ModalBody>
                </CustomModal>
            )}
            
            {showCreateExample && (
                <ExampleManagerModal
                    show={showCreateExample}
                    handleClose={() => setShowCreateExample(false)}
                    example={example}
                    document={example.document} />
            )}
        </div >
    )
}

export default DashboardExampleManager