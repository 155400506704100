import api from '$lib/api'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@utils'

export const getUsers = async (
    orderField = 'createdAt',
    orderDirection = 'DESC',
    offset = 0,
    limit = 25,
    query = null,
) => {
    
    const map = {
        order: `${orderField ?? 'createdAt'},${orderDirection ?? 'DESC'}`,
        offset,
        limit,
        query,
    }
    
    Object.keys(map).forEach(it => {
        if (map[it] === undefined)
            delete map[it]
    })
    
    const params = createQueryString(map)
    
    const res = await api.get(`/admin/users${params}`)
    
    if (res.data) {
        console.info('Fetched', res.data, 'users')
        
        // store.annotators.setValue(res.data)
    }
    
    return res
    
}

export const getUserById = async id => {
    
    if (id || !id) return console.warn('@todo not yet implemented')
    
    const res = await api.get(`/admin/users/${id}`)
    
    if (res.data) {
        
        console.info('Fetched user with ID', id, res.data)
        
        let values = [...store.users.getValue()]
        let userExists = false
        
        // Replace the user if they're already cached
        values = values.map(it => {
            
            if (it.id === id) {
                userExists = true
                return res.data
            }
            
            return it
            
        })
        
        // Add the new user if not
        if (!userExists)
            values.push(res.data)
        
        store.users.setValue(values)
        
    }
    
    return res
    
}

export const createUser = async (firstName, lastName, email) => {
    
    if (email || !email) return console.warn('@todo not yet implemented')
    
    return api.post('/admin/users', {
        firstName,
        lastName,
        email,
    })
    
}

export const updateUser = async (id, fields = {}) => {
    
    return api.put(`/admin/users/${id}`, fields)
    
}

export const deleteUsers = async ids => {
    
    if (!ids?.length)
        throw new Error('Param "ids" required')
    
    return api.delete('/admin/users', {
        data: {
            ids,
        },
    })
    
}

/**
 * Onboards a new user more conveniently
 * 
 * @param {string} email - The email of the new user
 * @param {string} firstName - The first name of the new user
 * @param {string} lastName - The last name of the new user
 * @param {UUID} organizationId - The ID of an existing organization to add the user to
 * @param {string|null}organizationName - The name of the new organization to create
 * @returns {Promise<any>}
 */
export const onboardNewUser = async (email, firstName, lastName, organizationId = null, organizationName = null) => {
    
    if (!organizationId && !organizationName)
        throw new Error('One of params "organizationId" and "organizationName" required')
    
    /* if (organizationId && organizationName)
        throw new Error('Only one of "organizationId" and "organizationName" can be provided') */
    
    return await api.post('/admin/users/onboard', {
        email,
        firstName,
        lastName,
        
        // Prefer the new org, if they've provided one
        organizationId: organizationName?.length > 0 ? undefined : organizationId,
        organizationName,
    })
    
}

export const masqueradeAsUser = async email => {
    
    const { data } = await api.post('/admin/users/masquerade', {
        email,
    })
    
    // @todo legacy
    localStorage.setItem('jwt', data.token)
    localStorage.setItem('userId', data.id)
    localStorage.setItem('userEmail', data.email)
    
    ParseAPI.defaults.headers = {
        'Content-Type': 'application/json',
        'x-api-key': data.token,
    }
    
    // Explicitly not using `saveMergedUserLocal` here,
    // since masquerade should override any saved data
    store.user.setValue(data)
    
    return data
    
}
