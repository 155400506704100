import { useMemo, Fragment, useEffect, useState } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { switchThemeNext, signOut } from '@actions'
import config from '@/config'
import { ROLE_ANNOTATOR_USER, ROLE_PARSE_ADMIN } from '@constants/roles'
import useUserHasRoles from '@hook/useUserHasRoles'
import cn from 'classnames'

import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import Gravatar from '@components/shared/Gravatar'
import AccountMenuItem from './AccountMenuItem'
import {
    MdOutlineAccountCircle,
    MdGroups,
    MdSettings,
    MdOutlineDashboard,
    MdOutlineLogout,
    MdOutlineHelp,
} from 'react-icons/md'
import { FaUsers } from 'react-icons/fa'

import './AccountMenu.css'
import ThemeIcon from '../ThemeSwitcher/ThemeIcon'
import { useNavigate } from 'react-router-dom'

const AccountMenu = ({
    className,
}) => {
    
    const navigate = useNavigate()
    const isAdmin = useUserHasRoles(ROLE_PARSE_ADMIN)
    
    const [avatarClassName, setAvatarClassName] = useState('')
    
    /** @type User */
    const user = useWireValue(store.user)
    /** @type WireState<GOrganization> */
    // const currentOrganization = useWireValue(store.currentOrganization)
    const remoteConfig = useWireValue(store.remoteConfig)
    const theme = useWireValue(store.theme)
    const credits = useWireValue(store.credits)
    const [blinkAvatarIndicator, setBlinkAvatarIndicator] = useWireState(store.blinkAvatarIndicator)
    
    const isEmployee = useMemo(() => (
        user?.email?.endsWith('@localhost.com') ||
        user?.email?.endsWith('@parseai.co')
    ), [user])
    
    const isAnnotator = useMemo(() => (
        user?.roles?.some(it => it.name === ROLE_ANNOTATOR_USER)
    ), [user])
    
    const paymentsConfig = useMemo(() => ({
        enabled: remoteConfig?.payments?.enabled || false,
        lowBalanceThreshold: remoteConfig?.payments?.lowBalanceThreshold || 10,
    }), [remoteConfig])
    
    const logOut = async e => {
        e.preventDefault()
        await signOut()
        navigate('/signin')
    }
    
    const switchTheme = e => {
        e.preventDefault()
        switchThemeNext()
    }
    
    useEffect(() => {
        
        if (blinkAvatarIndicator)
            setTimeout(() => {
                setAvatarClassName('animate-grayscaleOut')
                setBlinkAvatarIndicator(false)
            }, 3000)
        
    }, [blinkAvatarIndicator])
    
    return (
        
        <div className={cn('AccountMenu', className)}>
            
            <Menu as="div" className="menu">
                
                <div className="">
                    <MenuButton className="menu-toggle !w-min">
                        <span className="hidden md:inline text-end leading-none">
                            {user?.email}
                            {isAdmin && isEmployee && (<>
                                <br/>
                                <span className="text-xs text-red-300">
                                    ADMIN
                                </span>
                            </>)}
                        </span>
                        <Gravatar
                            className="user-avatar indicator"
                            imageClassName={cn('rounded-full', avatarClassName, {
                                'animate-grayscaleIn': blinkAvatarIndicator,
                            })}
                            size={32}>
                            {paymentsConfig.enabled && credits.user <= paymentsConfig.lowBalanceThreshold && (
                                <span className={cn('indicator-item badge badge-xs', {
                                    'animate-blink': blinkAvatarIndicator,
                                    'badge-error': credits.user <= (paymentsConfig.lowBalanceThreshold / 2),
                                    'badge-warning': credits.user > paymentsConfig.lowBalanceThreshold,
                                })} />
                            )}
                        </Gravatar>
                    </MenuButton>
                </div>
                
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    
                    <MenuItems className="menu-items">
                        
                        <div className="px-1 py-1">
                            <AccountMenuItem label="Account" to="/settings" Icon={MdOutlineAccountCircle} />
                            <AccountMenuItem label="Organizations" to="/settings/orgs" Icon={MdGroups} />
                            {/* @todo remove this check once themes are live */
                                remoteConfig?.themes?.enabled && (
                                    <AccountMenuItem label={`Theme: ${theme.name}`} onClick={switchTheme}>
                                        <ThemeIcon theme={theme} />
                                    </AccountMenuItem>
                                )}
                            <AccountMenuItem label="All Settings" to="/settings" Icon={MdSettings} />
                            
                            <AccountMenuItem label="Help" to={'https://success.parseai.co/knowledge-base'} 
                                target={'_blank'} Icon={MdOutlineHelp}/>
                            
                            {isAnnotator && (
                                <AccountMenuItem label="Partners" to="/partners" Icon={FaUsers} />
                            )}
                            {config.enableInternalDashboard && isEmployee && !isAnnotator && (
                                <AccountMenuItem
                                    labelClassName="text-success"
                                    label="Dashboard"
                                    to="/dashboard"
                                    Icon={MdOutlineDashboard} />
                            )}
                        </div>
                        
                        {paymentsConfig.enabled === true && (<>
                            <div className="px-1 py-1 menu-item-button flex-col">
                                <div className="w-full flex items-center justify-end gap-2">
                                    <span>Balance:</span>
                                    <span className="font-mono">
                                        ${(credits.user ?? 0).toFixed(2)}
                                    </span>
                                </div>
                                {credits.user <= paymentsConfig.lowBalanceThreshold && (
                                    <div className={cn('w-full text-xs', {
                                        'text-error': credits.user <= 1,
                                        'text-warning': credits.user > 1,
                                    })}>
                                        Low Balance
                                    </div>
                                )}
                            </div>
                            
                            <div className="px-1 py-1 menu-item-button flex-col">
                                <div className="w-full flex items-center justify-end gap-2">
                                    <span>Org Balance:</span>
                                    <span className="font-mono">
                                        ${(credits.organization ?? 0).toFixed(2)}
                                    </span>
                                </div>
                                {credits.organization <= paymentsConfig.lowBalanceThreshold && (
                                    <div className={cn('w-full text-xs', {
                                        'text-error': credits.organization <= 1,
                                        'text-warning': credits.organization > 1,
                                    })}>
                                        Low Balance
                                    </div>
                                )}
                            </div>
                        </>)}
                        
                        <div className="px-1 py-1">
                            <AccountMenuItem
                                data-test-id="AccountMenuLogoutButton"
                                className="!text-red-500"
                                label="Logout"
                                Icon={MdOutlineLogout}
                                onClick={logOut}/>
                        </div>
                    
                    </MenuItems>
                
                </Transition>
            
            </Menu>
        
        </div>
        
    )
    
}

export default AccountMenu
