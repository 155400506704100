
/**
 * Payment costs for different operations (values are in "tokens" aka $dollars)
 */

/** @type {PurchaseTypeViewIndexDocument} */
export const PURCHASE_TYPE_VIEW_INDEX_DOCUMENT = 'view_index_document'

/** @type {PurchaseTypeProcessDocument} */
export const PURCHASE_TYPE_PROCESS_DOCUMENT = 'process_document'

/** @type {PurchaseTypeAutoChain} */
export const PURCHASE_TYPE_AUTO_CHAIN = 'auto_chain'

export const PURCHASE_TYPES = [
    PURCHASE_TYPE_VIEW_INDEX_DOCUMENT,
    PURCHASE_TYPE_PROCESS_DOCUMENT,
    PURCHASE_TYPE_AUTO_CHAIN,
]

export const PURCHASE_TYPE_COSTS = {
    [PURCHASE_TYPE_VIEW_INDEX_DOCUMENT]: 2,
    [PURCHASE_TYPE_PROCESS_DOCUMENT]: 1,
    [PURCHASE_TYPE_AUTO_CHAIN]: 0.25,
}

export const PURCHASE_TYPE_MIN_COSTS = {
    [PURCHASE_TYPE_AUTO_CHAIN]: 5,
}

export const ACCOUNT_TYPES = {
    /** @type StripeBalancePurchaseAccountType */
    User: 'user',
    /** @type StripeBalancePurchaseAccountType */
    Organization: 'organization',
}
