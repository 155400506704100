import { useState, useMemo, useEffect, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWire, useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as appStore from '@store'
import * as actions from '$actions'
import useAbortableFetch from '@hook/useAbortableFetch'
import useSortableTableHeaders from '@hook/useSortableTableHeaders'
import useCheckableItems from '@hook/useCheckableItems'

const ITEMS_PER_PAGE_LIMIT = 50

const UsersViewModel = () => {
    
    const [searchParams, setSearchParams] = useSearchParams()
    
    const [loading, setLoading] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [count, setCount] = useState(0)
    const [users, setUsers] = useState([])
    const [query, setQuery] = useState('')
    const [prevQuery, setPrevQuery] = useState('')
    
    const remoteConfig = useWireValue(appStore.remoteConfig)
    const modalOnboardNewUserOpen = useWire(store.modalOnboardNewUserOpen)
    const modalDeleteUsersOpen = useWire(store.modalDeleteUsersOpen)
    
    // [slug, label, extraClassName]
    const headers = useMemo(() => (
        [
            ['id', 'ID', 'w-30'],
            ['createdAt', 'Created'],
            ['firstName', 'First Name'],
            ['lastName', 'Last Name'],
            ['email', 'Email', 'min-w-40'],
            ['enabled', 'Enabled'],
            ['admin', 'Admin'],
            remoteConfig?.payments?.enabled ? ['credits', 'Credits'] : null,
            ['roles', 'Roles'],
        ].filter(it => Boolean(it))
    ), [remoteConfig])
    
    const {
        orderField,
        orderDirection,
        onHeaderClick,
    } = useSortableTableHeaders(headers)
    
    const filteredUsers = useMemo(() => {
        
        if (!query?.length) return users
        
        return users.filter(it => {
            
            const fields = ['id', 'email', 'firstName', 'lastName']
            
            return fields.some(field => {
                return it[field]
                    .toLowerCase()
                    .includes(query.toLowerCase())
            })
            
        })
        
    }, [query, users])
    
    const checkedItemsState = useCheckableItems(filteredUsers)
    
    const checkedUsers = useMemo(() => (
        checkedItemsState.checked?.map(id => users.find(it => it.id === id))
    ), [checkedItemsState.checked, users])
    
    const fetchUsers = useCallback(async () => {
        
        if (loading) return
        
        setLoading(true)
        
        const offset = pageIndex * ITEMS_PER_PAGE_LIMIT
        
        try {
            
            const res = await actions.getUsers(
                orderField, orderDirection, offset, ITEMS_PER_PAGE_LIMIT, query)
            
            setCount(res.data.count)
            setUsers(res.data.rows)
            
        } catch (e) {
            
            console.error('fetchUsers', e)
            setCount(0)
            setUsers([])
            
        }
        
        setLoading(false)
        
    }, [loading, pageIndex, orderField, orderDirection, query])
    
    const onUserUpdated = data => {
        
        setUsers(prev => ([...prev]).map(it => {
            
            if (it.id !== data.id) return it
            
            return {
                ...it,
                ...data,
            }
            
        }))
        
    }
    
    const onUsersDeleted = () => {
        
        checkedItemsState.toggleAllChecked(false)
        
        return fetchUsers()
        
    }
    
    useAbortableFetch(() => {
        
        setSearchParams(prev => ({ ...prev, query }))
        
        return fetchUsers()
        
    }, [pageIndex, orderField, orderDirection, query])
    
    useEffect(() => {
        
        if (!searchParams.get('query')?.length) return
        
        const nextQuery = searchParams.get('query').trim()
        const isNewQuery = nextQuery.toString().trim() !== query.toString().trim()
        
        if (prevQuery?.length > 0 && isNewQuery) return
        
        if (prevQuery !== query)
            setPrevQuery(query)
        
        if (query !== nextQuery)
            setQuery(nextQuery)
        
    }, [searchParams, query, prevQuery])
    
    return {
        
        // Constants
        ITEMS_PER_PAGE_LIMIT,
        
        // Hooks
        searchParams,
        setSearchParams,
        
        // State
        loading,
        setLoading,
        pageIndex,
        setPageIndex,
        count,
        setCount,
        users,
        setUsers,
        query,
        setQuery,
        
        // Global State
        remoteConfig,
        modalOnboardNewUserOpen,
        modalDeleteUsersOpen,
        
        // Methods
        fetchUsers,
        onUserUpdated,
        onUsersDeleted,
        
        // Memos
        headers,
        filteredUsers,
        checkedUsers,
        checkedItemsState,
        
        // Methods
        onHeaderClick,
        
    }
    
}

export default UsersViewModel
