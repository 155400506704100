const SYSTEM_TYPE_COMMENTS = { key: 'comments', value: 'Comments' }
const SYSTEM_TYPE_LEGAL_DESCRIPTIONS_SUMMARIZER = { key: 'ld_summarizer', value: 'Legal Description Summarizer' }
const SYSTEM_TYPE_CHAINER = { key: 'chainer', value: 'Chainer' }
export const SYSTEM_TYPE_DEED_VALUES = { key: 'value_extractor', value: 'Value Extractor' }

export const SYSTEM_TYPES = [
    
    SYSTEM_TYPE_COMMENTS,
    SYSTEM_TYPE_LEGAL_DESCRIPTIONS_SUMMARIZER,
    SYSTEM_TYPE_CHAINER,
    SYSTEM_TYPE_DEED_VALUES,
    
]