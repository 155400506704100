import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import * as store from '@store'

const log = new Logger('actions/runsheetTabs')

export const createRunsheetTab = async (runsheetId, tab) => {
    
    try {
        
        const res = await ParseAPI.post(`runsheetTabs/${runsheetId}`, {
            tab,
        })
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            currentRunsheet.tabs = [...currentRunsheet.tabs, res.data]
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
        return res.data
        
    } catch (err) {
        log.e(err)
        
        throw err
    }
}

export const updateRunsheetTab = async (tabId, data) => {
    try {
        
        const res = await ParseAPI.patch(`runsheetTabs/${tabId}`, data)
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const tabIdx = currentRunsheet.tabs.findIndex(tab => tab.id === tabId)
            
            currentRunsheet.tabs.splice(tabIdx, 1, { ...currentRunsheet.tabs[tabIdx], ...(res.data[0]) })
            
            currentRunsheet.tabs = [...currentRunsheet.tabs]
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
        return res.data
        
    } catch (err) {
        log.e(err)
        
        throw err
    }
}

export const addDocumentValuesToRunsheetTab = async (runsheetId, tabId, documentIds) => {
    try {
        
        const res = await ParseAPI.post(`runsheetTabs/${tabId}/values`, {
            runsheetId,
            documentIds,
        })
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const tabIdx = currentRunsheet.tabs.findIndex(tab => tab.id === tabId)
            const tab = currentRunsheet.tabs[tabIdx]
            
            tab.joins = [...tab.joins, ...res.data]
            currentRunsheet.tabs.splice(tabIdx, 1, { ...tab })
            currentRunsheet.tabs = [...currentRunsheet.tabs]
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
        return res.data
        
    } catch (err) {
        log.e(err)
        
        throw err
    }
}

export const deleteRunsheetTab = async tabId => {
    try {
        
        const res = await ParseAPI.delete(`runsheetTabs/${tabId}`)
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const tabIdx = currentRunsheet.tabs.findIndex(tab => tab.id === tabId)
            
            currentRunsheet.tabs.splice(tabIdx, 1)
            
            currentRunsheet.tabs = [...currentRunsheet.tabs]
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
        return res.data
        
    } catch (err) {
        log.e(err)
        
        throw err
    }
}

export const removeDocumentsFromTab = async (runsheetId, tabId, documentIds) => {
    
    try {
        
        const res = await ParseAPI.post(`runsheetTabs/${tabId}/remove`, {
            runsheetId,
            documentIds,
        })
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const tabIdx = currentRunsheet.tabs.findIndex(tab => tab.id === tabId)
            const tab = currentRunsheet.tabs[tabIdx]
            
            tab.joins = tab.joins.filter(j => !res.data.includes(j.runsheetDocumentValueId))
            currentRunsheet.tabs.splice(tabIdx, 1, { ...tab })
            currentRunsheet.tabs = [...currentRunsheet.tabs]
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
        return res.data
        
    } catch (err) {
        log.e(err)
        
        throw err
    }
    
}