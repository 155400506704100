import { useCallback, useEffect, useMemo } from 'react'
import { useWire, useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { PURCHASE_TYPE_COSTS, PURCHASE_TYPE_MIN_COSTS, PURCHASE_TYPE_AUTO_CHAIN } from '@constants'
import { toast } from 'react-toastify'
import { calculateChainingCost } from '@/utils/purchases'

/**
 * View model for the `PaymentRequiredFlow`
 *
 * @param {StripeBalancePurchaseAccountType} fundingSource
 * @param {number} balance
 * @param {PaymentsHook} [paymentsHook] - Optionally provide your own payments hook
 * @param {PurchaseType} purchaseType
 * @param {number} itemCount
 * @param {function} onClose
 * @param {function} onConfirmPayment - Callback (remainingBalance) => {}
 * @param {function} onFundingSourceChange - Callback (fundingSource) => {}
 * @param {function} onInsufficientBalanceChange - Callback (hasInsufficientBalance) => {}
 * @returns {PaymentRequiredFlowViewModel}
 * @constructor
 */
const PaymentRequiredFlowViewModel = ({
    fundingSource,
    balance,
    purchaseType = null,
    itemCount = 1,
    onClose = null,
    onConfirmPayment = null,
    onFundingSourceChange = null,
    onInsufficientBalanceChange = null,
} = {}) => {
    
    //region Hooks
    
    const currentOrganization = useWireValue(store.currentOrganization)
    
    /** @type Wire<PaymentRequiredFlowConfig> */
    const config = useWire(store.paymentRequiredDialogConfig)
    
    /** @type {boolean} - if the user should be warned about needing to pay */
    const [shouldWarn, setShouldWarn] = useWireState(store.paymentRequiredDialogShouldWarn)
    
    /** @type {number} - fixed value */
    const minCost = useMemo(() => 
        PURCHASE_TYPE_MIN_COSTS[purchaseType] ?? null
    , [purchaseType])
    
    /** @type {number} - unit price */
    const unit = useMemo(() => PURCHASE_TYPE_COSTS[purchaseType], [purchaseType])
    
    /** @type {string} - unit price, currency-formatted */
    const unitFormatted = useMemo(() => unit?.toFixed(2), [unit])
    
    /** @type {number} - total price */
    const price = useMemo(() => {
        if (purchaseType === PURCHASE_TYPE_AUTO_CHAIN) {
            return calculateChainingCost(itemCount)
        }
        
        return ((unit ?? 0) * (itemCount || 0)) + (minCost ?? 0)
    }, [purchaseType, itemCount, unit, minCost])
    
    /** @type {string} - total price, currency-formatted */
    const priceFormatted = useMemo(() => price.toFixed(2), [price])
    
    /** @type {number} - remaining balance (user or organization) */
    const [remainingBalance, setRemainingBalance] = useWireState(store.remainingBalance)
    
    /** @type {string} - remaining balance, currency-formatted */
    const remainingBalanceFormatted = useMemo(() => remainingBalance?.toFixed(2), [remainingBalance])
    
    /** @type {boolean} - if the remaining balance is negative */
    const remainingBalanceIsNegative = useMemo(() => Math.trunc(remainingBalance) < 0, [remainingBalance])
    
    /** @type {boolean} - if the selected funding source has insufficient balance */
    const hasInsufficientBalance = useMemo(() => balance < price, [price, balance])
    
    //endregion Hooks
    
    /**
     * Closes the dialog, resetting the payment flow
     * config if no `onClose` callback is provided
     */
    const closeDialog = useCallback(() => {
        
        if (onClose && typeof onClose === 'function')
            return onClose()
        
        config.setValue({
            ...(config.getValue() || {}),
            open: false,
        })
        
    }, [config, onClose])
    
    /**
     * Calls the `onConfirmPayment` callback, then closes the dialog
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {
        
        if (hasInsufficientBalance)
            return toast.warning('Insufficient balance')
        
        await onConfirmPayment(remainingBalance, fundingSource)
        
        closeDialog()
        
    }
    
    useEffect(() => {
        onFundingSourceChange?.(fundingSource)
    }, [fundingSource])
    
    useEffect(() => {
        onInsufficientBalanceChange?.(hasInsufficientBalance)
    }, [hasInsufficientBalance])
    
    useEffect(() => {
        setRemainingBalance(Math.max(balance - price))
    }, [price, balance])
    
    return {
        
        // Global State
        currentOrganization,
        shouldWarn,
        setShouldWarn,
        
        // Memos
        unit,
        unitFormatted,
        price,
        priceFormatted,
        remainingBalance,
        remainingBalanceFormatted,
        remainingBalanceIsNegative,
        hasInsufficientBalance,
        minCost,
        
        // Methods
        closeDialog,
        handleSubmit,
        
    }
    
}

export default PaymentRequiredFlowViewModel
