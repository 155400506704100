import { useState } from 'react'
import { useWire } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import useCheckableItems from '@hook/useCheckableItems'
import { snippet } from '@utils'
import { relativeTime } from '@/utils/date'
import cn from 'classnames'

import ManageCollectionLabelsModal from '$components/Modals/ManageCollectionLabelsModal'
import SetDocumentStatusesModal from '$components/Modals/SetDocumentStatusesModal'
import Checkbox from '$components/Forms/Checkbox'
import ProgressBar from '@components/shared/ProgressBar'
import { Link } from 'react-router-dom'
import { MdBolt, MdLabel, MdOutlineChangeCircle } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { BsSortUp, BsSortDown } from 'react-icons/bs'

import './CollectionsTable.css'
import Toggle from '$components/Forms/Toggle'

const rowHeadersMap = {
    name: 'Name',
    createdAt: 'Created',
    updatedAt: 'Updated',
    
    // description: 'Description',
    'stats.documentCount': 'Documents',
    'stats.documentsProgressValue': 'Annotation Status',
}

const getProgressClass = (max, value) => {
    
    const percent = (value / max) * 100
    
    if (percent >= 100) return 'bg-success'
    if (percent >= 90) return 'bg-info'
    if (percent >= 75) return 'bg-info'
    if (percent >= 40) return 'bg-warn'
    
    return 'bg-danger'
    
}

/**
 * 
 * @param {string} className
 * @param {Collection[]} collections
 * @param {string} orderField
 * @param {string} orderDirection
 * @param {function} onRefreshCollections
 * @param {function} onHeaderClick
 * @param {function} onAssignAnnotatorClick
 * @returns {JSX.Element}
 * @constructor
 */
const CollectionsTable = ({
    className,
    collections,
    orderField,
    orderDirection,
    onRefreshCollections,
    onHeaderClick,
    onAssignAnnotatorClick,
}) => {
    
    const [selectedCollection, setSelectedCollection] = useState(null)
    
    const modalManageCollectionLabelsOpen = useWire(store.modalManageCollectionLabelsOpen)
    const modalSetDocumentStatusesOpen = useWire(store.modalSetDocumentStatusesOpen)
    
    const {
        allChecked,
        someChecked,
        hasChecked,
        toggleChecked,
        toggleAllChecked,
    } = useCheckableItems(collections)
    
    const onDeleteClick = async id => {
        
        if (!confirm('Are you sure you want to delete this collection?\n\n@todo replace this with a real dialog'))
            return
        
        try {
            await actions.deleteCollection(id)
            await onRefreshCollections()
        } catch (e) {
            // @todo show error
            console.error('onDeleteClick', e)
        }
        
    }
    
    const onManageLabelsClick = collection => {
        
        setSelectedCollection(collection)
        modalManageCollectionLabelsOpen.setValue(true)
        
    }
    
    const onSetDocumentStatusesClick = collection => {
        
        setSelectedCollection(collection)
        modalSetDocumentStatusesOpen.setValue(true)
        
    }
    
    const onToggleIsExample = async (collectionId, isExample) => {
        try {
            await actions.toggleIsExample(collectionId, isExample)
            await onRefreshCollections() 
        } catch (e) {
            console.error('onToggleIsExample', e)
        }
    }
    
    const onToggleIsEval = async (collectionId, isEval) => {
        
        try {
            await actions.toggleIsEval(collectionId, isEval)
            await onRefreshCollections() 
        } catch (e) {
            console.error('onToggleIsEval', e)
        }
    }
    
    const onToggleParseCollection = async (collectionId, isParse) => {
        try {
            await actions.toggleParseCollection(collectionId, isParse)
            await onRefreshCollections()
        } catch (e) {
            console.error('onToggleParseCollection', e)
        }
    }
    
    return (<>
        
        <table className={cn('CollectionsTable table table-auto table-pin-rows', className)}>
            <thead>
                <tr>
                    <th>
                        <Checkbox
                            checked={allChecked}
                            indeterminate={someChecked}
                            onChange={toggleAllChecked} />
                    </th>
                    <th>ID</th>
                    {Object.keys(rowHeadersMap).map(it => (
                        <th
                            key={`CollectionsTable-th-${it}`}
                            
                            onClick={() => onHeaderClick(it)}>
                            <div>
                                <span>{rowHeadersMap[it]}</span>
                                {orderField === it && (
                                    orderDirection === 'asc' ? <BsSortDown /> : <BsSortUp />
                                )}
                            </div>
                        </th>
                    ))}
                    <th>Parse Collection</th>
                    <th>Example Collection</th>
                    <th>Evaluation Collection</th>
                    <th>
                        {/* Actions */}
                        <div className="flex !justify-center">
                            <MdBolt className="text-lg" />
                        </div>
                    </th>
                
                </tr>
            </thead>
            <tbody className="list">
                {collections.map(it => (
                    <tr key={`CollectionsTable-job-${it.id}`}>
                        <td>
                            <Checkbox
                                checked={hasChecked(it.id)}
                                onChange={() => toggleChecked(it.id)} />
                        </td>
                        <td title={it.id}>{snippet(it.id)}</td>
                        <td>
                            <Link to={`/dashboard/collections/${it.id}`}>
                                {it.name}
                            </Link>
                        </td>
                        <td title={it.createdAt}>{relativeTime(it.createdAt)}</td>
                        <td title={it.updatedAt}>{relativeTime(it.updatedAt)}</td>
                        <td>{it?.stats?.documentsCount ?? 0}</td>
                        <td>
                            <div className="flex items-center content-center justify-between">
                                <ProgressBar
                                    className="mr-2 !w-[70%]"
                                    barClassName={getProgressClass(
                                        it.stats.documentsProgressMax,
                                        it.stats.documentsCompletedCount,
                                    )}
                                    max={it.stats.documentsProgressMax}
                                    value={it.stats.documentsCompletedCount} />
                                <div className="flex items-center content-center text-xs text-right shrink-0">
                                    {it.stats.documentsCompletedCount}/{it.stats.documentsProgressMax}
                                </div>
                            </div>
                        </td>
                        <td>
                            <Toggle
                                className="cursor-pointer"
                                checked={it.isParse}
                                onChange={value => onToggleParseCollection(it.id, value)} />
                        </td>
                        <td>
                            <Toggle
                                className="cursor-pointer"
                                checked={it.isExample}
                                onChange={value => onToggleIsExample(it.id, value)}/>
                        </td>
                        <td>
                            <Toggle
                                className="cursor-pointer"
                                checked={it.isEval}
                                onChange={value => onToggleIsEval(it.id, value)}/>
                        </td>
                        <td>
                            <div className="flex items-center content-center justify-center">
                                <button
                                    className="w-auto text-red-800 btn btn-link hover:text-red-500"
                                    title="Delete Collection"
                                    onClick={() => onDeleteClick(it.id)}>
                                    <FaTrash />
                                </button>
                                <button
                                    className="w-auto text-lg btn btn-link"
                                    title="Manage Labels"
                                    onClick={() => onManageLabelsClick(it)}>
                                    <MdLabel />
                                </button>
                                <button
                                    className="w-auto text-lg btn btn-link"
                                    title="Set Document Statuses"
                                    onClick={() => onSetDocumentStatusesClick(it)}>
                                    <MdOutlineChangeCircle />
                                </button>
                                <button
                                    className="w-auto text-xs btn btn-link"
                                    title="Assign annotators"
                                    onClick={() => onAssignAnnotatorClick(it)}>
                                    ASSIGN
                                </button>
                            </div>
                        </td>
                    
                    </tr>
                ))}
            </tbody>
        </table>
        
        <ManageCollectionLabelsModal collection={selectedCollection} />
        <SetDocumentStatusesModal collection={selectedCollection} />
    
    </>)
    
}

export default CollectionsTable
