import api from '$lib/api'
import * as store from '$store'
import { createQueryString } from '@utils'

export const getOrganizations = async (offset = 0, limit = 50) => {
    
    if (store.isFetchingOrgs.getValue()) return
    
    store.isFetchingOrgs.setValue(true)
    
    const query = createQueryString({
        offset,
        limit,
    })
    
    const res = await api.get(`/admin/organizations${query}`)
    
    if (res.data) {
        console.info('Fetched', res.data?.length, 'organizations')
        store.organizations.setValue(res.data)
    }
    
    store.isFetchingOrgs.setValue(false)
    
    return res
    
}

export const getOrganizationById = async id => {
    
    const res = await api.get(`/admin/organizations/${id}`)
    
    if (res.data)
        store.organizations.setValue([
            ...(store.organizations.getValue() ?? []).filter(it => it.id !== res.data.id),
            res.data,
        ])
    
    return res
    
}

export const getOrganizationRoles = async () => {
    
    const res = await api.get('/admin/organizations/roles')
    
    return res.data
    
}

export const updateOrganization = async (id, fields = {}) => {
    
    return api.put(`/admin/organizations/${id}/credits`, fields)
    
}

export const addMemberToOrganization = async (orgId, userEmail, memberRole) => {
    
    const res = await api.put(`/admin/organizations/${orgId}/join`, {
        email: userEmail,
        memberRole,
    })
    
    if (res.data)
        store.organizations.setValue([
            ...(store.organizations.getValue() ?? []).filter(it => it.id !== res.data.id),
            res.data,
        ])
    
    return res
    
}

export const removeMemberFromOrganization = async (orgId, memberId) => {
    
    const res = await api.delete(`/admin/organizations/${orgId}/members/${memberId}`)
    
    store.organizations.setValue([
        ...(store.organizations.getValue() ?? []).filter(it => it.id !== res.data.id),
    ])
    
    return res
    
}

/**
 * Adds labels to a organization
 * 
 * @param {UUID} organizationId 
 * @param {String[]} labelIds 
 */
export const addLabelsToOrganization = async (organizationId, labelIds) => {
    
    const res = await api.put(`/organizations/${organizationId}/labels`, {
        organizationId,
        labelIds,
    })
    
    return res
    
}

/**
 * Replaces all labels for a organization
 * 
 * @param {UUID} organizationId 
 * @param {String[]} labelIds 
 */
export const setLabelsToOrganization = async (organizationId, labelIds) => {
    
    const res = await api.post(`/orgs/${organizationId}/labels`, {
        organizationId,
        labelIds,
    })
    
    return res
    
}

/**
 * Deletes labels from a organization
 * 
 * @param {UUID} organizationId 
 * @param {String[]} labelIds 
 */
export const removeLabelsFromOrganization = async (organizationId, labelIds) => {
    
    const res = await api.post(`/orgs/${organizationId}/labels`, {
        organizationId,
        labelIds,
    })
    
    return res
    
}
