import { MdClose, MdNotifications } from 'react-icons/md'
import cn from 'classnames'
import * as store from '@store'
import * as actions from '@actions'

import './NotificationCenter.css'
import { useWireValue } from '@forminator/react-wire'
import {
    ESCROW_STATUS_FAILED,
    ESCROW_STATUS_FULFILLED,
    ESCROW_STATUS_PENDING,
    HUMAN_ESCROW_TYPES,
} from '@/constants/escrows'
import { useCallback } from 'react'

const NotificationCenter = () => {
    
    const escrowNotifications = useWireValue(store.escrows)
    
    const getStatusText = useCallback(status => {
        if (status === ESCROW_STATUS_PENDING)
            return 'Still processing'
        else if (status === ESCROW_STATUS_FULFILLED)
            return 'Processing completed'
        else if (status === ESCROW_STATUS_FAILED)
            return 'Processing failed'
        return null
    }, [])
    
    const dismissAllNotifications = ev => {
        [...ev.target.parentElement.parentElement.querySelectorAll('li.notification')]
            .forEach(li => li.dataset.dismissing = true)
        actions.dismissAllNotifications()
    }
    
    const dismissNotification = (ev, id) => {
        ev.target.parentElement.dataset.dismissing = true
        actions.dismissNotification(id)
    }
    
    return (
        <div className="notification-wrapper relative">
            
            <input id="notification-toggle" type="checkbox" className="notification-toggle" />
            
            <label htmlFor="notification-toggle" className="notification-icon" role="button" tabIndex={0}>
                <div className="relative">
                    {!!escrowNotifications?.length &&
                        <span className="indicator-item badge badge-primary select-none">
                            {escrowNotifications.length}
                        </span>}
                    <MdNotifications className="text-2xl" />
                </div>
            </label>
            
            <div className="notification-center">
                <div className="notification-header">
                    <span>Notifications</span>
                    {!!escrowNotifications.length &&
                        <button className="underline py-0 px-1 text-xs"
                            onClick={dismissAllNotifications}>Dismiss All</button>}
                </div>
                {!escrowNotifications.length &&
                    <div className="flex-1 flex flex-col justify-center text-center py-4 select-none">
                        <div>All Done!</div>
                        <div>Nothing to see here.</div>
                    </div>
                }
                <ul className="notification-list">
                    {escrowNotifications.map(en =>
                        <li key={en.id} className="notification">
                            <div className="info-wrapper">
                                <span 
                                    data-tip={getStatusText(en.status)}
                                    className={cn('tooltip tooltip-right badge badge-xs', {
                                        'badge-neutral tooltip-neutral': en.status === ESCROW_STATUS_PENDING,
                                        'badge-success tooltip-success': en.status === ESCROW_STATUS_FULFILLED,
                                        'badge-error tooltip-error': en.status === ESCROW_STATUS_FAILED,
                                    })} />
                                <div className="info">
                                    <span className="description">
                                        <span>{en.description}</span>
                                    </span>
                                    <span className="type">{HUMAN_ESCROW_TYPES[en.transactionType]}</span>
                                </div>
                            </div>
                            <button className="btn btn-ghost btn-xs !px-1"
                                onClick={ev => dismissNotification(ev, en.id)}>
                                <MdClose className="pointer-events-none" />
                            </button>
                        </li>,
                    )}
                </ul>
            </div>
        
        </div>
        
        
    )
}

export default NotificationCenter