import Logger from '@utils/log'
import { memo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as appActions from '@actions'
import * as constants from '@constants'
import { setNamespace } from 'react-wire-persisted'
import useInit from '@hook/useInit'
import useTheme from '@hook/useTheme'
import { useDebug } from '@hook/useDebug'
import { useSubscribeIdentifyUser } from '@utils/logrocket'
import cn from 'classnames'

import Sidebar from '$components/Sidebar'

import '$styles/index.css'

const log = new Logger('DashboardApp')

setNamespace(constants.NS)

const DashboardApp = ({
    children,
}) => {
    
    const navigate = useNavigate()
    
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    
    const user = useWireValue(store.user)
    const isFetchingUser = useWireValue(store.isFetchingUser)
    
    useEffect(() => {
        if (isFetchingUser) return
        appActions.fetchProfile()
    }, [])
    
    useEffect(() => {
        
        const t = setTimeout(() => {
            
            if (user) return
            
            if (localStorage.getItem('jwt')?.length) return
            
            log.w('Dashboard not authorized')
            navigate('/signin')
            
        }, 1000)
        
        return () => clearTimeout(t)
        
    }, [user])
    
    useInit()
    useSubscribeIdentifyUser()
    useTheme()
    useDebug()
    
    return (
        
        <div className={cn('Dashboard', {
            'sidebar-collapsed': sidebarCollapsed,
        })}>
            
            <Sidebar
                collapsed={sidebarCollapsed}
                setCollapsed={setSidebarCollapsed} />
            
            <main>
                {children}
            </main>
        
        </div>
        
    )
    
}

export default memo(DashboardApp)
